import React, {Component} from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";



const styles = theme => ({});


class Scrollbar extends Component {



    render() {

        const {children} = this.props;

        return (

            <Scrollbars>
                {children}
            </Scrollbars>

        )
    }


}

Scrollbar.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Scrollbar);
