import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {Redirect, withRouter} from "react-router-dom";
import {compose} from "redux";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";


// Local Files
import ShiftDayPanel from "../shift/ShiftDayPanel";
import MainArea from "../layout/MainArea";
import Loading from "./EditShiftPlan";


moment.locale('de');

const styles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    div:{
        padding: theme.spacing.unit,
        width: "100%",
        overflowX: 'auto',
    },
    table: {
        width: "100%",
    },
    tableCell: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    }
});

const NR_OF_WORK_DAYS = 6;

function compareShifts(a, b) {
    const startDiff = a.startDate - b.startDate;
    const endDiff = a.endDate - b.endDate;

    return (startDiff === 0) ?  Math.sign(endDiff) : Math.sign(startDiff);
}

class PrintShiftPlan extends Component {


    state = {}


    render() {


        const {classes, publicData, initData} = this.props;
        const shiftPlanID = this.props.match.params.id;

        if(publicData === undefined || initData === undefined){
            return (
                <Loading />
            )
        }

        // get the shiftplan object
        let myShiftPlan = null;
        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        try {

            let shiftPlan = shiftPlans[shiftPlanID];

            myShiftPlan = {
                id: shiftPlanID,
                startDate: moment(shiftPlan.startDate.seconds * 1000),
                state: shiftPlan.state,
                shifts: shiftPlan.shifts? shiftPlan.shifts: [],
            }
        } catch (e) {
            console.log('shiftPlan can not be loaded correctly', shiftPlanID, e)
        }


        if(!myShiftPlan){
            return (
                <Redirect to={`/shiftplan-not-found`}/>
            )
        }


        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        // get the shift objects of this shiftplan
        let myShifts = [];

        myShiftPlan.shifts.map( (shiftID) => {

            //try to load the shifts
            try {

                let shift = shifts[shiftID];

                myShifts.push({
                    id: shiftID,
                    startDate: moment(shift.startDate.seconds * 1000),
                    endDate: moment(shift.endDate.seconds * 1000),
                    drivers: shift.drivers,
                    selectedDriver: shift.selectedDriver,

                })
            } catch (e) {
                console.log('shift can not be loaded correctly', shiftID, e)
            }

        });

        myShifts = myShifts.sort(compareShifts);

        let myShiftsPerDay = [];

        [...Array(NR_OF_WORK_DAYS)].map((x, i) => {
            myShiftsPerDay[i] = myShifts.filter((shift) => {
                return shift.startDate.isSame(moment(myShiftPlan.startDate).add(i, 'days'), 'day')
            })
        });

        const users = publicData.users;


        return (
            <MainArea>

                {[...Array(NR_OF_WORK_DAYS)].map((x, i) =>
                    <div key={i} className={classes.div}>
                        <Typography className={classes.heading}>
                            {moment(myShiftPlan.startDate).add(i, 'days').format('dd, Do MMMM YYYY')}
                        </Typography>

                        {myShiftsPerDay[i].length !== 0 ? (

                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.tableCell}>Schicht</TableCell>
                                        <TableCell align="left" className={classes.tableCell}>Start</TableCell>
                                        <TableCell align="left" className={classes.tableCell}>Ende</TableCell>
                                        <TableCell align="left" className={classes.tableCell}>Fahrer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {myShiftsPerDay[i].map((shift, j) =>
                                        <TableRow key={i * j + j}>
                                            <TableCell align="center" className={classes.tableCell}>
                                                {j + 1}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                {`${shift.startDate.format('LT')} Uhr`}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                {`${shift.endDate.format('LT')} Uhr`}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCell}>
                                                {users[shift.selectedDriver] ?
                                                    `${users[shift.selectedDriver].firstName} 
                                                    ${users[shift.selectedDriver].lastName}`
                                                    : ''
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography> Keine Schicht geplant. </Typography>
                        )

                        }
                    </div>
                    )}
                    </MainArea>
        );

        return (
            <MainArea>

                {[...Array(NR_OF_WORK_DAYS)].map((x, i) =>
                    <ShiftDayPanel
                        key={i}
                        displayDate={moment(myShiftPlan.startDate).add(i, 'days')}
                        shiftPlanID={shiftPlanID}
                        mode={"view"}
                    />
                )}
            </MainArea>
        );

    }

}

PrintShiftPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(PrintShiftPlan);
