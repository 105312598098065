import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import moment from 'moment'
import "moment/locale/de";
// Material UI
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
// Local Files
import ShiftCard from './ShiftCard'
import AddShiftCard from "./AddShiftCard";
import Authorization from "../auth/Authorization";

moment.locale('de');
const styles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        align: 'right',
    },
    secondaryHeading_green: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.secondary.main,
        align: 'right',
    },
    expansionPanelDetails: {
        padding: "0px 0px 0px",
    },
    expansionPanel: {
        width: "100%",
    },
    column: {
        flexBasis: '50%',
    },

});

function compareShifts(a, b) {
    const startDiff = a.startDate - b.startDate;
    const endDiff = a.endDate - b.endDate;

    return (startDiff === 0) ?  Math.sign(endDiff) : Math.sign(startDiff);
}


class ShiftDayPanel extends Component {

    state = {}


    render() {

        const {classes,
            displayDate, mode, shiftPlanID,
            auth, publicData, initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(auth === undefined || publicData === undefined || initData === undefined){
            return (
                <div/>
            )
        }

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        // get the shiftplan object
        let myShiftPlan = null;

        try {

            let shiftPlan = shiftPlans[shiftPlanID];

            myShiftPlan = {
                id: shiftPlanID,
                startDate: moment(shiftPlan.startDate.seconds * 1000),
                state: shiftPlan.state,
                shifts: shiftPlan.shifts? shiftPlan.shifts: [],
            }
        } catch (e) {
            console.log('shiftPlan can not be loaded correctly', shiftPlanID, e)
        }



        // if there is no object something must be wrong
        if(myShiftPlan === null){
            return(
                <div/>
            )
        }

        // get the shift objects of this shiftplan
        let myShifts = []

        myShiftPlan.shifts.map( (shiftID) => {

            //try to load the shifts
            try {

                let shift = shifts[shiftID];

                myShifts.push({
                    id: shiftID,
                    startDate: moment(shift.startDate.seconds * 1000),
                    endDate: moment(shift.endDate.seconds * 1000),
                    drivers: shift.drivers,
                    selectedDriver: shift.selectedDriver,

                })
            } catch (e) {
                console.log('shift can not be loaded correctly', shiftID, e)
            }

        });

        myShifts = myShifts.filter((shift) => {
            return shift.startDate.isSame(this.props.displayDate, 'day')
        }).sort(compareShifts)


        const userID = auth.uid;
        let hoursPerDay = 0;
        let nrSelectedShifts = 0;
        let nrMySelectedShifts = 0;
        let nrMyAppliedShifts = 0;

        myShifts.map( (shift) => {

            hoursPerDay += shift.endDate.diff(shift.startDate, 'hours', true);
            nrSelectedShifts =
                shift.selectedDriver !== ''? nrSelectedShifts + 1: nrSelectedShifts;
            nrMySelectedShifts =
                shift.selectedDriver === userID? nrMySelectedShifts + 1: nrMySelectedShifts;
            shift.drivers.map( (driverID) => {
                nrMyAppliedShifts =
                    driverID === userID? nrMyAppliedShifts + 1: nrMyAppliedShifts;
            })
        })

        return (
            <ExpansionPanel className={classes.expansionPanel}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={16}
                                alignItems="center"
                                direction="row"
                                justify="space-between"
                            >
                                <Grid item>
                                    <Typography className={classes.heading}>
                                        {displayDate.format('dd, Do MMMM YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Authorization roles={['driver']}>
                                        <Typography
                                            className={nrMySelectedShifts === 0 ?
                                                classes.secondaryHeading :
                                                classes.secondaryHeading_green}
                                        >
                                            {`Offen: ${myShifts.length - nrSelectedShifts}`}
                                        </Typography>
                                    </Authorization>

                                    <Authorization roles={['businessOwner', 'desk']}>
                                        <Typography className={classes.secondaryHeading}>
                                            {`${myShifts.length} / ${hoursPerDay}h`}
                                        </Typography>
                                    </Authorization>

                                    <Authorization>
                                        <Typography
                                            className={nrSelectedShifts !== myShifts.length ?
                                                classes.secondaryHeading :
                                                classes.secondaryHeading_green}
                                        >
                                            {`${nrSelectedShifts} von ${myShifts.length} / ${hoursPerDay}h`}
                                        </Typography>
                                    </Authorization>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>

                {
                    //just show in edit mode
                    mode === 'edit' &&
                    <Authorization
                        roles={['admin', 'businessOwner']}
                    >
                        <AddShiftCard date={displayDate} shiftPlanID={shiftPlanID} shiftPlanState={myShiftPlan.state}/>
                    </Authorization>
                }

                {myShifts.map((shift, index) => (
                    <ExpansionPanelDetails
                        className={classes.expansionPanelDetails}
                        key={shift.id}>
                        <ShiftCard
                            avatarText={index + 1}
                            shiftID={shift.id}
                            mode={mode}
                        />
                    </ExpansionPanelDetails>
                ))}

            </ExpansionPanel>
        );

    }

}

ShiftDayPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ShiftDayPanel);
