import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {Redirect, withRouter} from "react-router-dom";
import {compose} from "redux";

// Material UI
import {withStyles} from '@material-ui/core/styles';



// Local Files
import ShiftDayPanel from "../shift/ShiftDayPanel";
import MainArea from "../layout/MainArea";
import Loading from "./EditShiftPlan";

moment.locale('de');

const styles = theme => ({});

const NR_OF_WORK_DAYS = 6


class ViewShiftPlan extends Component {


    state = {}


    render() {


        const {classes, publicData} = this.props;
        const shiftPlanID = this.props.match.params.id;

        if(publicData === undefined){
            return (
                <Loading />
            )
        }

        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        // try to load the shiftplan object
        let myShiftPlan = null;

        try {

            let shiftPlan = shiftPlans[shiftPlanID];

            myShiftPlan = {
                id: shiftPlanID,
                startDate: moment(shiftPlan.startDate.seconds * 1000),
                state: shiftPlan.state,
                shifts: shiftPlan.shifts? shiftPlan.shifts: [],
            }
        } catch (e) {
            console.log('shiftPlan can not be loaded correctly', shiftPlanID, e)
        }


        if(!myShiftPlan){
            return (
                <Redirect to={`/shiftplan-not-found`}/>
            )
        }

        return (
            <MainArea>

                {[...Array(NR_OF_WORK_DAYS)].map((x, i) =>
                    <ShiftDayPanel
                        key={i}
                        displayDate={moment(myShiftPlan.startDate).add(i, 'days')}
                        shiftPlanID={shiftPlanID}
                        mode={"view"}
                    />
                )}
            </MainArea>
        );

    }

}

ViewShiftPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ViewShiftPlan);
