const initState = {
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_USER_SUCCESS':
            console.log("ACTION_USER", action.user);
            return {
                ...state,
                ...action.user
            }
        case 'GET_USER_ERROR':
            return state;
        default:
            return state;
    }
};

export default userReducer;