const initState = {
};

const carReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default carReducer;
