import React, {Component} from 'react';
import moment from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import { TimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import {withStyles} from "@material-ui/core";



const styles = theme => ({
    timePicker: {
        width: "100%",
    },
    picker: {
        width: "100%",
    }

});

moment.locale("de");

class KeyboardTimePicker extends Component {

   state = {
       value: this.props.value,
   }


    handleChange = (e) => {

        if (typeof this.props.onChange === "function") {

            var event = {
                target: {
                    id: this.props.id,
                    value: e.toDate(),
                }
            }

            this.props.onChange(event);
        }

        //e is a moment object
        this.setState({value: e.toDate()});
    }


    render(){

        const { classes, label } = this.props;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale='de' moment={moment} width="100%">
                <div className={classes.picker}>
                    <TimePicker
                        className={classes.timePicker}
                        keyboard
                        ampm={false}
                        label={label}
                        mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                        placeholder="08:00"
                        value={this.state.value}
                        onChange={this.handleChange}
                        disableOpenOnEnter
                        minutesStep={5}
                    />
                </div>
            </MuiPickersUtilsProvider>
        )


    }

}

export default withStyles(styles)(KeyboardTimePicker);