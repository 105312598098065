// react
import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { compose } from 'redux';

// redux
import { connect } from 'react-redux';
import Loading from "../layout/Loading";
import AdminDashboard from "./AdminDashboard";
import DriverDashboard from "./DriverDashboard";
import DeskDashboard from "./DeskDashboard";


class Dashboard extends Component {

    render(){

        const { auth, publicData } = this.props;

        // if the data are not here just render a empty div
        if(auth === undefined || publicData === undefined) {
            return <Loading/>
        }

        // Default user role is role = 'desk'
        let user_role = 'desk';
        try{
            user_role = publicData.users[auth.uid].role
        }catch(e){
            console.log('no user role found')
        }


        switch (user_role) {
            case 'admin':
                return(<AdminDashboard/>);
            case 'driver':
                return(<DriverDashboard/>);
            case 'desk':
                return(<DeskDashboard/>);
            case 'businessOwner':
                return(<DeskDashboard/>);
            default:
                return (<Loading/>)
        }
    }

}

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Dashboard);