// react
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

// material-ui
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Poll from '@material-ui/icons/Poll';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import ListAlt from '@material-ui/icons/ListAlt';

import {loadShiftArchive} from "../../store/actions/shiftActions";
import {loadShiftPlanArchive} from "../../store/actions/shiftPlanActions";


const styles = {
    root: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        marginTop: 1,
        boxShadow: "0px -1px 0px 0px Gainsboro",
    },
};

class BottomNav extends React.Component {

    state = {
        value: 0,
        archiveLoaded: false,
    }

    handleChange = (event, value) => {

        this.setState({ value });

        switch(value) {
            case 0:
                this.props.history.push('/');
                break;
            case 1:
                this.props.history.push('/viewshiftplan');
                break;
            case 2:
                this.props.history.push('/myshifts/'+this.props.auth.uid);
                break;
            default:
                this.props.history.push('/');
        }
    };

    render() {
        const { classes, auth, publicData } = this.props;
        const { value } = this.state;

        // load archive
        if(!this.state.archiveLoaded){
            this.props.loadShiftArchive();
            this.props.loadShiftPlanArchive();
            this.state.archiveLoaded = true;
        }

        // render nothing until the auth and roles object is loaded from redux
        if(auth === undefined || publicData === undefined) return null;

        // check if the user is logged in, if not render nothing
        if(auth.isEmpty) return null;

        // get the user role, default is desk
        let user_role = 'desk';
        try{
            user_role = publicData.users[auth.uid].role
        }catch(e){
            console.log('no user role found')
        }

        return (
            (() => {
                switch(user_role) {
                    case 'admin':
                        return (
                            <BottomNavigation
                                value={value}
                                onChange={this.handleChange}
                                showLabels
                                className={classes.root}
                            >
                                <BottomNavigationAction label="Übersicht" icon={<Poll />} />
                                <BottomNavigationAction label="Schichtplan" icon={<ListAlt />} />
                            </BottomNavigation>
                        );
                    case 'businessOwner':
                        return (
                            <BottomNavigation
                                value={value}
                                onChange={this.handleChange}
                                showLabels
                                className={classes.root}
                            >
                                <BottomNavigationAction label="Übersicht" icon={<Poll />} />
                                <BottomNavigationAction label="Schichtplan" icon={<ListAlt />} />
                            </BottomNavigation>
                        );
                    case 'desk':
                        return (
                            <BottomNavigation
                                value={value}
                                onChange={this.handleChange}
                                showLabels
                                className={classes.root}
                            >
                                <BottomNavigationAction label="Übersicht" icon={<Poll />} />
                                <BottomNavigationAction label="Schichtplan" icon={<ListAlt />} />
                            </BottomNavigation>
                        );
                    default: // driver
                        return (
                            <BottomNavigation
                                value={value}
                                onChange={this.handleChange}
                                showLabels
                                className={classes.root}
                            >
                                <BottomNavigationAction label="Übersicht" icon={<Poll />} />
                                <BottomNavigationAction label="Schichtplan" icon={<ListAlt />} />
                                <BottomNavigationAction label="Meine Schicht" icon={<TimeToLeave />} />
                            </BottomNavigation>
                        )
                }
            })()

        );
    }
}

BottomNav.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadShiftArchive: () => dispatch(loadShiftArchive()),
        loadShiftPlanArchive: () => dispatch(loadShiftPlanArchive())
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    firestoreConnect([
        {collection: 'public'},
        {collection: 'init'},
    ])
)(BottomNav);