import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {HorizontalBar} from 'react-chartjs-2';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/es/Paper/Paper";



// Local Files



moment.locale('de');



const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    div:{
        margin: 2 * theme.spacing.unit,
    }
});

const chart_data = {
    labels: [],
    datasets: [
        {
            label: 'Summe der Stunden',
            backgroundColor: 'rgba(109,207,109,0.2)',
            borderColor: 'rgba(51,136,51,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(109,207,109,0.4)',
            hoverBorderColor: 'rgba(51,136,51,1)',
            data: []
        }
    ]
};


class WorkingHoursPanel extends Component {




    render(){

        const {classes,
            auth, publicData, initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(auth === undefined || publicData === undefined || initData === undefined){
            return (
                <Paper>
                    Loading...
                </Paper>
            )
        }

        const users = publicData.users;
        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        let user = null;
        let myShifts = [];

        //try to get the user object
        try {
            user = users[auth.uid];

            let selectedShifts = user.selectedShifts ? user.selectedShifts : []

            selectedShifts.map(shiftID => {

                //try to load the user's shifts
                try {

                    let shift = shifts[shiftID];

                    myShifts.push({
                        id: shiftID,
                        startDate: moment(shift.startDate.seconds * 1000),
                        endDate: moment(shift.endDate.seconds * 1000),
                        drivers: shift.drivers,
                        selectedDriver: shift.selectedDriver,

                    })
                } catch (e) {
                    console.log('shift can not be loaded correctly', shiftID, shifts[shiftID], e)
                }
            })

        } catch (e) {
            console.log('user can not be loaded correctly', auth.uid, e)
        }

        // Calc the displayed data
        let data = {};

        myShifts.reverse();

        myShifts.map(shift => {
            const month = shift.startDate.format("MMM gg");

            if (data[month] === undefined) {
                data[month] = {
                    count: 0,
                    total: 0,
                }
            }
            //Update the count and total hours of a month
            data[month].count += 1;
            data[month].total += shift.endDate.diff(shift.startDate, 'hours', true);
        })

        const labels = Object.keys(data);
        const dataset1 = []

        labels.map(label => {
            chart_data.datasets[0].data.push(data[label].total)
        })


        chart_data.labels = labels;

        return (

            <Paper className={classes.root}>
                <div className={classes.div}>
                    <HorizontalBar
                        data={chart_data}
                        legend={{ display: false }}
                        options={
                            {scales: {
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                    }
                                }]
                            }}}
                    />
                </div>

            </Paper>

        );

    }

}

WorkingHoursPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(WorkingHoursPanel) );