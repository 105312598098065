const initState = {
};

const shiftReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_SHIFT_SUCCESS':
            return state;
        case 'UPDATE_SHIFT_ERROR':
            return state;
        case 'CREATE_SHIFT_SUCCESS':
            return state;
        case 'CREATE_SHIFT_ERROR':
            return state;
        case 'APPLY_SHIFT_SUCCESS':
            console.log('APPLY_SHIFT_SUCCESS')
            return state;
        case 'APPLY_SHIFT_ERROR':
            return state;
        case 'SELECT_DRIVER_SUCCESS':
            return state;
        case 'LOAD_ARCHIVE_SHIFT_SUCCESS':
            return {
                ...state,
                archive: action.shifts
            }
        default:
            return state;
    }
};

export default shiftReducer;
