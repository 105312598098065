// react
import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";

// material-ui
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import './App.css';

// own
import BottomNav from './components/layout/BottomNav';
import TopNav from './components/layout/TopNav';
import SignIn from './components/auth/SignIn';
import CreateUser from "./components/user/CreateUser";
import ViewUser from "./components/user/ViewUser";
import EditUser from "./components/user/EditUser";
import CreateShiftPlan from "./components/shiftplan/CreateShiftPlan";
import EditShiftPlan from "./components/shiftplan/EditShiftPlan";
import ViewShiftPlan from "./components/shiftplan/ViewShiftPlan";
import ViewAllShiftPlans from "./components/shiftplan/ViewAllShiftPlans";
import NotFound from "./components/layout/NotFound";
import Dashboard from "./components/dashboard/Dashboard";
import MyShifts from "./components/shift/MyShifts";
import PrintShiftPlan from "./components/shiftplan/PrintShiftPlan";


const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            light: '#333333',
            main: '#000000',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6dcf6d',
            main: '#49c349',
            dark: '#338833',
            contrastText: '#000',
        },
        error: {
            light: "#f4ff81",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
        }
    },
});

class App extends Component {
    render() {

        const {auth} = this.props

        if(!auth) return <div />

        let isLoggedOut = (!auth.uid);

        if(isLoggedOut){

            return (
                <BrowserRouter>
                    <div className="App">
                        <MuiThemeProvider theme={theme}>
                            <TopNav/>

                            <Switch>
                                <Route path='/' component={SignIn}/>
                            </Switch>

                        </MuiThemeProvider>
                    </div>
                </BrowserRouter>
            )
        }

        return (
            <BrowserRouter>
                <div className="App">
                    <MuiThemeProvider theme={theme}>
                        <TopNav/>

                        <Switch>
                            <Route exact path='/' component={Dashboard}/>

                            <Route path='/createuser' component={CreateUser}/>
                            <Route path='/viewuser/:id' component={ViewUser}/>
                            <Route path='/edituser/:id' component={EditUser}/>

                            <Route path='/createshiftplan' component={CreateShiftPlan}/>
                            <Route path='/editshiftplan/:id' component={EditShiftPlan}/>
                            <Route path='/viewshiftplan/:id' component={ViewShiftPlan}/>
                            <Route path='/printshiftplan/:id' component={PrintShiftPlan}/>

                            <Route exact path='/viewshiftplan' component={ViewAllShiftPlans}/>

                            <Route path='/myshifts/:id' component={MyShifts}/>

                            <Route component={NotFound}/>
                        </Switch>

                        <BottomNav/>
                    </MuiThemeProvider>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
    }
};

// export default App;

export default compose(
    connect(mapStateToProps)
)(App);
