// react
import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

// material-ui
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// own components
import {signUp} from '../../store/actions/authActions'
import MainArea from "../layout/MainArea";
import Authorization from "../auth/Authorization";

const styles = theme => ({
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

class CreateUser extends Component {

    state = {
        // basic
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        // role
        role: 'driver'
    }

    handleSubmit = (e) => {

        e.preventDefault()

        this.props.signUp({
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            phone: this.state.phone.trim(),
            password: this.state.password,
            role: this.state.role,
        })
    }

    handleChange = (e) => {

        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        const {classes, authError} = this.props


        return (
            <MainArea>
                <Authorization redirect={true}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Benutzer anlegen
                    </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="role">Benutzer-Rolle</InputLabel>
                            <Select
                                value={this.state.role}
                                onChange={this.handleChangeSelect}
                                autoFocus
                                name='role'
                                id="role"
                                inputProps={{
                                    id: 'role',
                                }}
                                className={classes.selectEmpty}
                            >
                                <MenuItem value="driver">Fahrer</MenuItem>
                                <MenuItem value="businessOwner">Auftraggeber</MenuItem>
                                <MenuItem value="desk">Lounge Mitarbeiter</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="firstName">Vorname</InputLabel>
                            <Input id="firstName"
                                   name="firstName"
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="lastName">Nachname</InputLabel>
                            <Input id="lastName"
                                   name="lastName"
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="phone">Mobil-Nummer</InputLabel>
                            <Input id="phone"
                                   name="phone"
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Adresse</InputLabel>
                            <Input id="email"
                                   name="email"
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth autoComplete="new-password">
                            <InputLabel htmlFor="password">Passwort</InputLabel>
                            <Input name="password"
                                   type="password"
                                   id="password"
                                   onChange={this.handleChange}/>
                        </FormControl>

                        {authError ? <Typography align={"center"} color="error"> {authError} </Typography> : null}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign up
                        </Button>
                    </form>
                </Authorization>
            </MainArea>
        );

    }

}

CreateUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (creds) => dispatch(signUp(creds))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateUser));