import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
// Material UI
import {withStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';

// Local Files


const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
        width: "100%",
        justifyContent: 'left',
    },
});

class UserChip extends Component {

    state = {
        user: this.props.user,
        userRedirect: false,
    }

    handleClick = (e) => {

        if (typeof this.props.onClick === "function") {

            var event = {
                target: {
                    id: this.props.user.id,
                }
            }

            this.props.onClick(event);
        }
        else{
            this.setState({userRedirect: true})
        }



    }


    render() {

        const {classes, user, color} = this.props;

        let userID = user.id

        if (this.state.userRedirect && userID!== '') {
            return <Redirect to={'/viewuser/' + userID} />
        }

        return (
            <Chip
                color={color}
                onClick={this.handleClick}
                className={classes.chip}
                avatar={
                    <Avatar>
                        <FaceIcon onClick={this.handleClick}/>
                    </Avatar>
                }
                label={
                    <Typography variant="inherit" onClick={this.handleClick}>
                        {user.firstName + " " + user.lastName}
                    </Typography>}
            />

        );

    }

}

UserChip.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {}

}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserChip));