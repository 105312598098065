import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

// own reducers
import authReducer from './authReducer';
import shiftPlanReducer from './shiftPlanReducer';
import userReducer from "./userReducer";
import shiftReducer from './shiftReducer';
import carReducer from "./carReducer";


const rootReducer = combineReducers({
    auth: authReducer,
    _shiftPlan: shiftPlanReducer,
    _shift: shiftReducer,
    _user: userReducer,
    _car: carReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;