import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Scrollbar from "./Scrollbar";

// Local Files



const BackgroundImage = '//www.kiwilabs.de/background_shuttle_app/';


const styles = theme => ({
    root:{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: "100%",
        width: '100%',
        position: 'fixed',
        top: 0,
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 50,
        paddingTop: 50,
        height: 'calc(100% - 100px)',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 150,
            paddingBottom: 150,
            height: 'auto'
        },
        [theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
            width: 1000,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 150,
            paddingBottom: 150,
            height: 'auto'
        },
    },
    paper: {
        marginTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: "100%",
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    spacingPaper: {
        width: "100%",
        borderRadius: 0,
        boxShadow: "0px 0px",
        marginTop: 50,
    }
});

class MainArea extends Component {

    state = {

    }


    render(){

        const { classes, children } = this.props;

        return (
            <div className={classes.root}>
                <Scrollbar>
                    <main className={classes.main}>
                        <Paper className={classes.paper}>
                            {children}
                            <Hidden mdUp>
                                <Paper className={classes.spacingPaper} />
                            </Hidden>
                        </Paper>
                    </main>
                </Scrollbar>
            </div>
        );

    }

}

MainArea.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(MainArea) );