import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import moment from 'moment'
import "moment/locale/de";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Local Files
import MainArea from "../layout/MainArea";
import ShiftPlanCard from "./ShiftPlanCard";
import Loading from '../layout/Loading';
import Authorization from "../auth/Authorization";
import {Typography} from "@material-ui/core";


const styles = theme => ({
    button: {
        padding: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },


});

function compareShiftPlans(a, b) {
    if (a.startDate < b.startDate)
        return -1;
    if (a.startDate > b.startDate)
        return 1;
    return 0;
}


class ViewAllShiftPlans extends Component {

    //ShiftPlan Element into State
    state = {
        createRedirect: false
    }

    handleCreate = (e) => {
        this.setState({
            createRedirect: true
        })
    }

    render() {


        const {classes, publicData} = this.props;


        if (publicData === undefined) {
            return (
                <Loading/>
            )
        }

        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        //shiftPlans is a nested object and shall be convert to an array
        let myShiftPlans = []

        for (let shiftPlanID in shiftPlans) {

            //try to load the shiftplan
            try {

                let shiftPlan = shiftPlans[shiftPlanID];

                myShiftPlans.push({
                    id: shiftPlanID,
                    startDate: moment(shiftPlan.startDate.seconds * 1000),
                    state: shiftPlan.state,
                    shifts: shiftPlan.shifts ? shiftPlan.shifts : [],

                });

            } catch (e) {
                console.log('shiftplan can not be loaded correctly', shiftPlanID, e)
            }
        }



        myShiftPlans = myShiftPlans.sort(compareShiftPlans).reverse();

        const myDriverShiftPlans = myShiftPlans.filter((shiftPlan) => {
            return shiftPlan.state > 1;
        })


        if (this.state.createRedirect) {
            return (
                <Redirect to='/createshiftplan'/>
            )
        }

        return (

            <MainArea>

                <Authorization roles={['admin', 'businessOwner']}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={this.handleCreate}
                        className={classes.button}
                    >
                        Neuen Schichtplan anlegen
                    </Button>
                </Authorization>

                <Authorization roles={['admin', 'businessOwner']}>

                    {myShiftPlans.length === 0 &&
                    <Typography variant="h6" color='inherit'>
                        Es stehen noch keine Schichtpläne zur Anzeige bereit.
                    </Typography>}

                    {myShiftPlans.map((shiftplan, index) => (
                        <ShiftPlanCard
                            key={index}
                            shiftPlanID={shiftplan.id}
                        />

                    ))}
                </Authorization>

                <Authorization roles={['driver','desk']}>

                    {myDriverShiftPlans.length === 0 &&
                    <Typography variant="h6" color='inherit'>
                        Es stehen noch keine Schichtpläne zur Anzeige bereit.
                    </Typography>}

                    {myDriverShiftPlans.map((shiftplan, index) => (
                        <ShiftPlanCard
                            key={index}
                            shiftPlanID={shiftplan.id}
                        />

                    ))}
                </Authorization>


            </MainArea>
        );

    }

}

ViewAllShiftPlans.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ViewAllShiftPlans);