import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {compose} from "redux";


// Material UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';

// Local Files
import ViewAdditionalUser from './ViewAdditionalUser';
import Loading from '../layout/Loading';
import MainArea from "../layout/MainArea";
import Authorization from "../auth/Authorization";
import IconButton from "@material-ui/core/es/IconButton/IconButton";



const ProfilImage = '//www.kiwilabs.de/wp-content/uploads/2018/09/usa-49909_1280-2-614x1024.jpg';


const styles = theme => ({

    bigAvatar: {
        margin: 10,
        width: 200,
        height: 200,
    },
    gridContainer: {
        spacing: 24,
        alignItems: "center",
        justify: "center"

    },
});

class ViewUser extends Component {

    state={
        editRedirect: false,
    }

    handleEdit = () => {
        this.setState({
            editRedirect: true
        })
    }


    render() {
        const {classes, publicData} = this.props;

        const userID = this.props.match.params.id;

        if(publicData === undefined) return (<Loading/>)

        const user = publicData.users[userID];

        if (!user) {
            return <Redirect to='/user-not-found'/>
        }

        if (this.state.editRedirect) {
            return <Redirect to={'/edituser/' + userID} />
        }

        return (

            <MainArea>
                <Grid container
                      spacing={16}
                      justify="center"
                      alignItems="center"
                >

                    <Avatar alt="Remy Sharp" src={ProfilImage} className={classes.bigAvatar}/>

                    <Grid item xs={12}>
                        <Authorization roles={['admin']}>

                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={this.handleEdit}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        </Authorization>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {user.firstName + " " + user.lastName}
                        </Typography>
                        <Typography gutterBottom variant="inherit">
                            {user.email}
                        </Typography>
                        <Typography gutterBottom variant="inherit">
                            {'Tel: ' + user.phone}
                        </Typography>
                    </Grid>

                    <Authorization ownProfile={true}>
                        <Grid item xs={12}>
                            <ViewAdditionalUser id={userID}/>
                        </Grid>
                    </Authorization>

                </Grid>
            </MainArea>
        );

    }

}

ViewUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {}
};


// export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewUser));

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ViewUser);