import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
// Material UI
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import {Typography} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';


// Local Files
import {setStateShiftPlan} from "../../store/actions/shiftPlanActions";
import Authorization from "../auth/Authorization";

moment.locale('de');
const NR_SEC_PER_HOUR = 60 * 60;

const styles = theme => ({
    card: {
        width: "100%",
        marginBottom: theme.spacing.unit,
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    actions: {
        display: 'flex',
        paddingTop: theme.spacing.unit,
        paddingBottom: 0,
    },
    avatar: {
        width: 32,
        height: 32,
    },
    avatar_green: {
        width: 32,
        height: 32,
        backgroundColor: theme.palette.secondary.main,
    },
    avatar_yellow: {
        width: 32,
        height: 32,
        backgroundColor: theme.palette.error.light,
    },
    avatar_error: {
        width: 32,
        height: 32,
        backgroundColor: theme.palette.error.main,
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
        align: 'right',
    },
});

const WorkDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']


class ShiftPlanCard extends Component {

    state = {
        editRedirect: false,
        viewRedirect: false,
        printRedirect: false,
    }

    handleApprove = () => {

        this.props.setStateShiftPlan(this.props.shiftPlanID)

    }

    handleEdit = () => {
        this.setState({
            editRedirect: true
        })
    }

    handleView = () => {
        this.setState({
            viewRedirect: true
        })
    }

    handlePrint = () => {
        this.setState({
            printRedirect: true
        })
    }


    render() {


        const {classes, shiftPlanID, publicData, initData, auth} = this.props;


        if (this.state.editRedirect) {
            return (
                <Redirect to={`/editshiftplan/${shiftPlanID}`}/>
            )
        }

        if (this.state.viewRedirect) {
            return (
                <Redirect to={`/viewshiftplan/${shiftPlanID}`}/>
            )
        }

        if (this.state.printRedirect) {
            return (
                <Redirect to={`/printshiftplan/${shiftPlanID}`}/>
            )
        }

        // Wait until shiftPlans and shifts are loaded
        if (publicData === undefined || auth === undefined || initData === undefined) {
            return (
                <Card className={classes.card}/>
            )
        }

        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        // try to load the shiftplan object
        let myShiftPlan = null;

        try {

            let shiftPlan = shiftPlans[shiftPlanID];

            myShiftPlan = {
                id: shiftPlanID,
                startDate: moment(shiftPlan.startDate.seconds * 1000),
                state: shiftPlan.state,
                shifts: shiftPlan.shifts ? shiftPlan.shifts : [],
            }
        } catch (e) {
            console.log('shiftPlan can not be loaded correctly', shiftPlanID, e)
        }


        if (!myShiftPlan) {
            return (
                <Card className={classes.card}>
                    Fehler: Shiftplan konnte nicht geladen werden.
                </Card>
            )
        }


        // get the shift objects of this shiftplan
        let myShifts = []

        myShiftPlan.shifts.map((shiftID) => {

            //try to load the shifts
            try {

                let shift = shifts[shiftID];

                myShifts.push({
                    id: shiftID,
                    startDate: moment(shift.startDate.seconds * 1000),
                    endDate: moment(shift.endDate.seconds * 1000),
                    drivers: shift.drivers,
                    selectedDriver: shift.selectedDriver,

                })
            } catch (e) {
                console.log('shift can not be loaded correctly', shiftID, e)
            }

        });


        // grab the shift start and create a moment object makes life easier
        let start = myShiftPlan.startDate;

        return (
            <Card className={classes.card}>
                <CardHeader
                    title={`KW ${start.week()}`}
                    subheader={`${start.format('L')} -
                        ${start.day(7).format('L')}`}
                />

                <CardContent>

                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >

                        {WorkDays.map((day, index) => {

                                let shiftsPerDay = myShifts.filter((shift) => {
                                    return (moment(shift.startDate).day() === index + 1)
                                })

                                const userID = auth.uid;
                                let hoursPerDay = 0;
                                let nrSelectedShifts = 0;
                                let nrMySelectedShifts = 0;
                                let nrMyAppliedShifts = 0;

                                shiftsPerDay.map((shift) => {

                                    hoursPerDay += shift.endDate.diff(shift.startDate, 'hours', true);
                                    nrSelectedShifts =
                                        shift.selectedDriver !== '' ? nrSelectedShifts + 1 : nrSelectedShifts;
                                    nrMySelectedShifts =
                                        shift.selectedDriver === userID ? nrMySelectedShifts + 1 : nrMySelectedShifts;
                                    shift.drivers.map((driverID) => {
                                        nrMyAppliedShifts =
                                            driverID === userID ? nrMyAppliedShifts + 1 : nrMyAppliedShifts;
                                    })
                                })

                                return (
                                    <Grid item key={index}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Authorization roles={['driver']}>
                                                <Grid item>
                                                    <Avatar
                                                        className={(nrMySelectedShifts === 0
                                                            && shiftsPerDay.length - nrSelectedShifts > 0 && classes.avatar_yellow) ||
                                                        (nrMySelectedShifts === 0 && classes.avatar) ||
                                                        (nrMySelectedShifts === 1 && classes.avatar_green) ||
                                                        (nrMySelectedShifts > 1 && classes.avatar_error)}
                                                    >
                                                        <Typography>
                                                            {day}
                                                        </Typography>
                                                    </Avatar>
                                                </Grid>
                                            </Authorization>
                                            <Authorization roles={['admin', 'businessowner', 'desk']}>
                                                <Grid item>
                                                    <Avatar
                                                        className={nrSelectedShifts === shiftsPerDay.length ?
                                                            classes.avatar_green : classes.avatar}
                                                    >
                                                        <Typography>
                                                            {day}
                                                        </Typography>
                                                    </Avatar>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>
                                                        {shiftsPerDay.length}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>
                                                        {`${Math.round(hoursPerDay * 10) / 10} h`}
                                                    </Typography>
                                                </Grid>
                                            </Authorization>
                                            <Authorization>
                                                <Grid item>
                                                    <Typography>
                                                        {`${nrSelectedShifts}/${shiftsPerDay.length}`}
                                                    </Typography>
                                                </Grid>
                                            </Authorization>
                                            <Authorization roles={['driver']}>
                                                <Grid item>
                                                    <Typography>
                                                        {`${shiftsPerDay.length - nrSelectedShifts}/${shiftsPerDay.length}`}
                                                    </Typography>
                                                </Grid>
                                            </Authorization>


                                        </Grid>
                                    </Grid>
                                )
                            }
                        )}

                    </Grid>

                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Authorization>
                            <Grid item>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    {`Status:${myShiftPlan.state}`}
                                </Typography>
                            </Grid>
                        </Authorization>
                        <Grid item>

                            <Authorization>
                                <IconButton
                                    aria-label="Approve"
                                    onClick={this.handleApprove}
                                >
                                    <CloudDoneIcon/>
                                </IconButton>
                            </Authorization>


                            {   //Business Owner only allow to edit shifts if ShiftPlan is at status = 0
                                myShiftPlan.state === 0 &&
                                <Authorization roles={['businessOwner']}>
                                    <IconButton
                                        aria-label="Approve"
                                        onClick={this.handleApprove}
                                    >
                                        <CloudDoneIcon/>
                                    </IconButton>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={this.handleEdit}>
                                        <CreateIcon/>
                                    </IconButton>
                                </Authorization>
                            }

                            {
                                //Drivers only allow to edit shifts if ShiftPlan is at status = 2
                                myShiftPlan.state === 2 &&
                                <Authorization roles={['driver']}>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={this.handleEdit}>
                                        <CreateIcon/>
                                    </IconButton>
                                </Authorization>
                            }
                            <Authorization>
                                <IconButton
                                    aria-label="Print"
                                    onClick={this.handlePrint}>
                                    <PrintIcon/>
                                </IconButton>
                            </Authorization>

                            <Authorization>
                                <IconButton
                                    aria-label="Edit"
                                    onClick={this.handleEdit}>
                                    <CreateIcon/>
                                </IconButton>
                            </Authorization>


                            <IconButton
                                aria-label="View"
                                onClick={this.handleView}>
                                <VisibilityIcon/>
                            </IconButton>

                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );

    }

}

ShiftPlanCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setStateShiftPlan: (shiftPlanID, status) => dispatch(setStateShiftPlan(shiftPlanID, status))
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ShiftPlanCard);
