import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";


// Material UI
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'
import CardHeader from '@material-ui/core/CardHeader';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBoarderIcon from '@material-ui/icons/FavoriteBorder';
import StarIcon from '@material-ui/icons/Star';
import StarBoarderIcon from '@material-ui/icons/StarBorder';


// Local Files
import {applyShift, deleteShift, unapplyShift, selectDriver} from "../../store/actions/shiftActions";
import Authorization from "../auth/Authorization";

moment.locale("de");

const styles = theme => ({
    cardHeader: {
        paddingBottom: 0,
    },
    icon: {
        paddingRight: 5,
    },
    star: {
        color: '#ffeb3b',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        width: 32,
        height: 32,
        margin: 8,
    },
    container: {
        padding: 0,
        maxWidth: '100%'
    },

});


class ShiftCardHeader extends Component {

    state = {
        id: '',
        startDate: this.props.shift.startDate,
        endDate: this.props.shift.endDate,
        drivers: this.props.shift.drivers,
        selectedDriver: this.props.shift.selectedDriver,
        expanded: false,
        actionClicked: false,
        hasApplied: false,
        applyClicked: false,
        isSelected: false,
        selectClicked: false,

    }

    handleDelete = (e) => {
        console.log("handleDelete", this.props.shift.id)
        this.setState({actionClicked: true})
        this.props.deleteShift(this.props.shift.id)
    }

    handleApplyClick = () => {

        // check applyClicked to ensure that just on click happens one at a time
        if (!this.state.applyClicked) {

            if (!this.state.hasApplied) {
                this.props.applyShift(this.props.shift.id);
            } else {
                this.props.unapplyShift(this.props.shift.id);
                // if the driver was selected, than unselect him
                if (this.state.isSelected) {
                    this.props.selectDriver(this.props.shift.id, "");
                }

            }

            this.setState({
                applyClicked: true
            })


        }

    };

    handleSelectClick = () => {

        // check applyClicked to ensure that just on click happens one at a time
        if (!this.state.selectClicked) {

            if (!this.state.isSelected) {
                this.props.selectDriver(this.props.shift.id, this.props.auth.uid);

            } else {
                this.props.selectDriver(this.props.shift.id, "");
            }

            this.setState({
                selectClicked: true
            })


        }

    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };


    render() {

        const {classes, avatarText, mode, shift, auth} = this.props;

        const {startDate, endDate} = this.state;

        if (auth === undefined) {
            return (<CardHeader/>)
        }

        const diffHourString = '' + endDate.diff(startDate, 'hours');
        const diffMinutesString = (endDate.diff(startDate, 'minutes') % 60) < 10 ?
            '0' + (endDate.diff(startDate, 'minutes') % 60) :
            (endDate.diff(startDate, 'minutes') % 60);

        //check if user has applied for this shift
        let lastHasApplied = this.state.hasApplied;
        this.state.hasApplied = false;

        if (shift.drivers.includes(auth.uid)) {
            this.state.hasApplied = true
        }

        // There was a change in the applied state
        if (lastHasApplied !== this.state.hasApplied) {
            this.state.applyClicked = false
        }

        //check if user is selected for this shift
        let lastIsSelected = this.state.isSelected;
        this.state.isSelected = false;

        if (shift.selectedDriver === auth.uid) {
            this.state.isSelected = true
        }

        // There was a change in the selected Driver
        if (lastIsSelected !== this.state.isSelected) {
            this.state.selectClicked = false
        }

        const someoneElseSelected = shift.selectedDriver !== '' && shift.selectedDriver !== auth.uid;


        return (

            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Avatar aria-label="Shift Number" className={classes.avatar}>
                        {avatarText}
                    </Avatar>
                }
                action={
                    <div>
                        {mode === "edit" &&
                        <Authorization roles={['admin', 'businessOwner']}>
                            <IconButton
                                aria-label="Delete"
                                disabled={this.state.actionClicked}
                                onClick={this.handleDelete}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Authorization>
                        }
                        <Authorization roles={['driver']}>

                            <IconButton
                                disabled={mode === 'view' || this.state.applyClicked}
                                aria-label="Apply"
                                onClick={this.handleApplyClick}
                            >

                                {this.state.hasApplied && <FavoriteIcon color='error'/>}

                                {!this.state.hasApplied && <FavoriteBoarderIcon/>}

                            </IconButton>

                            {this.state.hasApplied && mode === 'edit' && !someoneElseSelected &&
                            <div>
                                <IconButton
                                    disabled={mode === 'view' || this.state.selectClicked}
                                    aria-label="SelectMe"
                                    onClick={this.handleSelectClick}
                                >

                                    {this.state.isSelected && <StarIcon className={classes.star}/>}

                                    {!this.state.isSelected && <StarBoarderIcon/>}

                                </IconButton>
                            </div>
                            }

                        </Authorization>

                    </div>
                }
                title={
                    <Grid container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          className={classes.container}
                    >
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <TransferWithinAStationIcon className={classes.icon}/>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {startDate.format('LT')
                                        + ' - ' +
                                        endDate.format('LT')
                                        + ' Uhr'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} item sm={6}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <AccessAlarmIcon className={classes.icon}/>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {`${diffHourString}:${diffMinutesString} h`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                }
            />
        );

    }

}

ShiftCardHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteShift: (shiftID) => dispatch(deleteShift(shiftID)),
        applyShift: (shiftID) => dispatch(applyShift(shiftID)),
        unapplyShift: (shiftID) => dispatch(unapplyShift(shiftID)),
        selectDriver: (shiftID, selectedDriver) => dispatch(selectDriver(shiftID, selectedDriver)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ShiftCardHeader));