import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/es/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";



// Local Files



moment.locale('de');



const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
    },
    card: {
        width: "100%",
        borderRadius: 0,
        height: 400,
    },
    icon:{
        padding: theme.spacing.unit,
    },
    snack:{
        marginTop: 55,

    },
    snackContent:{
        backgroundColor: theme.palette.secondary.main,
    }

});



class DatabaseCheckCard extends Component {

    checkShiftPlans = (shiftPlans, shifts) => {

        //shiftPlans is a nested object and shall be convert to an array
        let myShiftPlans = [];
        let myErrors = [];

        for(let shiftPlanID in shiftPlans) {

            //try to load the shiftplan
            try {

                let shiftPlan = shiftPlans[shiftPlanID];

                myShiftPlans.push({
                    id: shiftPlanID,
                    startDate: moment(shiftPlan.startDate.seconds * 1000),
                    state: shiftPlan.state,
                    shifts: shiftPlan.shifts? shiftPlan.shifts: [],

                })


                shiftPlan.shifts.map(shiftID => {
                    // Does the ref shift exists in shifts?
                    if (shifts[shiftID] === undefined){
                        let e = 'shift does not exist:' + shiftID;
                        myErrors.push({shiftPlanID : shiftPlanID, error: e})
                    };
                })

            } catch (e) {
                myErrors.push({shiftPlanID : shiftPlanID, error: e})
            }
        }

        return {shiftPlans: myShiftPlans, errors: myErrors}
    };

    checkShifts = (shifts, shiftPlans, users) => {
        // get the shift objects of this shiftplan
        let myShifts = [];
        let myErrors = [];

        for(let shiftID in shifts) {

            //try to load the shifts
            try {

                let shift = shifts[shiftID];

                myShifts.push({
                    id: shiftID,
                    startDate: moment(shift.startDate.seconds * 1000),
                    endDate: moment(shift.endDate.seconds * 1000),
                    drivers: shift.drivers ? shift.drivers: [],
                    selectedDriver: shift.selectedDriver,
                    shiftPlanID: shift.shiftPlanID
                })

                // Does the ref shiftplan exists in shiftPlans?
                if (shiftPlans[shift.shiftPlanID] === undefined){
                    let e = 'shiftPlan does not exist:' + shift.shiftPlanID;
                    myErrors.push({shiftID : shiftID, error: e})
                };

                // Does the ref shiftplan ref back to shift?
                if (shiftPlans[shift.shiftPlanID] !== undefined &&
                    !shiftPlans[shift.shiftPlanID].shifts.includes(shiftID)){
                    let e = 'shiftPlan does not refers back to the shift:' + shift.shiftPlanID;
                    myErrors.push({shiftID : shiftID, error: e})
                };

                // Does the ref selectedDriver exists in users?
                if(shift.selectedDriver !== ''){
                    if(users[shift.selectedDriver] === undefined){
                        let e = 'selectedDriver does not exist:' + shift.selectedDriver;
                        myErrors.push({shiftID : shiftID, error: e})
                    }
                    else {
                        if(users[shift.selectedDriver].selectedShifts.includes(shiftID) === false){
                            let e = 'shift is not referenced at the user selectedShifts:' + shift.selectedDriver;
                            myErrors.push({shiftID : shiftID, error: e})
                        }
                    }
                }

            } catch (e) {
                myErrors.push({shiftID : shiftID, error: e})
            }

        };

        return {shifts: myShifts, errors: myErrors}
    }

    checkUsers = (users, shifts) => {
        // get the shift objects of this shiftplan
        let myUsers = [];
        let myErrors = [];

        for(let userID in users) {

            //try to load the users
            try {

                let user = users[userID];

                myUsers.push({
                    id: userID,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    email: user.email,
                    appliedShifts: user.appliedShifts? user.appliedShifts: [],
                    selectedShifts: user.selectedShifts? user.selectedShifts: [],
                    role: user.role? user.role : 'desk',
                })

                // Does the ref Drivers exists in users
                if(user.appliedShifts){
                    user.appliedShifts.map(shiftID => {
                        // Does the ref shift exists in shifts?
                        if (shifts[shiftID] === undefined){
                            let e = 'shiftID does not exist:' + shiftID;
                            myErrors.push({userID : userID, error: e})
                        }
                        else{
                            if(shifts[shiftID].drivers.includes(userID) === false){
                                let e = 'user is not referenced at the shifts drivers:' + shiftID;
                                myErrors.push({userID : userID, error: e})
                            }
                        };
                    })
                }

                // Does the ref Drivers exists in users
                if(user.selectedShifts){
                    user.selectedShifts.map(shiftID => {
                        // Does the ref shift exists in shifts?
                        if (shifts[shiftID] === undefined){
                            let e = 'shiftID does not exist:' + shiftID;
                            myErrors.push({userID : userID, error: e})
                        }
                        else{
                            if(shifts[shiftID].selectedDriver !== userID){
                                let e = 'user is not referenced at the shift as selectedDriver:' + shiftID;
                                myErrors.push({userID : userID, error: e})
                            }
                        };
                    })
                }


            } catch (e) {
                myErrors.push({userID : userID, error: e})
            }

        };

        return {users: myUsers, errors: myErrors}
    }

    render(){

        const { classes,
        publicData, initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(publicData === undefined ||
            initData === undefined){
            return (
                <Paper>
                    Loading...
                </Paper>
            )
        }

        console.log(this.props)

        const users = publicData.users;
        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        let myShiftPlans = this.checkShiftPlans(shiftPlans, shifts);
        let myShifts = this.checkShifts(shifts, shiftPlans, users);
        let myUsers = this.checkUsers(users, shifts)


        // Print to the console a String for the invoice
        if ( myShifts !== undefined) {


            const NR_OF_MONTH = moment().subtract(1 , 'month').month();
            const YEAR = moment().year()
            let result = '';
            let total = 0;

            // grap just shifts from the selected month
            let shiftsInMonth = myShifts.shifts.filter((shift) => {
                return shift.startDate.month() === NR_OF_MONTH && shift.startDate.year() === YEAR
            });

            // generate a column for each day of the month
            for(let i = 1; i <= moment().month(NR_OF_MONTH).daysInMonth(); i++){

                // Filter the shifts for each day
                let shiftsPerDay = shiftsInMonth.filter((shift) => {
                    return shift.startDate.date() === i
                });

                if (shiftsPerDay.length === 0){
                    result += '\\\\' + '\n'
                }
                else {

                    let hoursPerDay = 0;

                    // Calc the total nr of hours of all shift per day
                    shiftsPerDay.map( (shift) => {

                        hoursPerDay += shift.endDate.diff(shift.startDate, 'hours', true);
                        total += shift.endDate.diff(shift.startDate, 'hours', true);

                    });


                    result += shiftsPerDay[0].startDate.format("ddd")
                        + ' & ' + shiftsPerDay[0].startDate.format("Do MMM YYYY")
                        + ' & ' + shiftsPerDay[0].startDate.week()
                        + ' & ' + hoursPerDay + 'h \\\\' + '\n'

                }

            }

            console.log(result)
            console.log("Total: " + total)

        }





        return (

            <Paper className={classes.root}>
                <Typography>
                    {`Schichtpläne : ${myShiftPlans.shiftPlans.length}
                    Fehler: ${myShiftPlans.errors.length}`}
                </Typography>
                {myShiftPlans.errors.map( (e, index) => {

                    return (<Typography key={'p'+index}>
                        {`SchichtplanID : ${e.shiftPlanID}
                    Fehler: ${e.error}`}
                    </Typography>)
                })}
                <Typography>
                    {`Schichten : ${myShifts.shifts.length}
                    Fehler: ${myShifts.errors.length}`}
                </Typography>
                {myShifts.errors.map((e, index) => {

                    return (<Typography  key={'s'+index}>
                        {`SchichtID : ${e.shiftID}
                    Fehler: ${e.error}`}
                    </Typography>)
                })}
                <Typography>
                    {`User : ${myUsers.users.length}
                    Fehler: ${myUsers.errors.length}`}
                </Typography>
                {myUsers.errors.map((e, index) => {

                    return (<Typography key={'u'+index}>
                        {`UserID : ${e.userID}
                    Fehler: ${e.error}`}
                    </Typography>)
                })}
            </Paper>

        );

    }

}

DatabaseCheckCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(DatabaseCheckCard) );