import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import {IconButton, Typography} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery30Icon from '@material-ui/icons/Battery30';
import Modal from '@material-ui/core/Modal';
import Slider from '@material-ui/lab/Slider';
import Button from '@material-ui/core/Button';

// Local Files
import {updateBatteryLevel} from "../../store/actions/carActions";



moment.locale('de');


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const styles = theme => ({
    flashIcon: {
        color: '#ffeb3b',
    },
    batteryIcon: {
        transform: 'rotate(90deg)',
        color: theme.palette.secondary.main,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
        width: 24,
        height: 24,
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    slider: {
        padding: '22px 0px',
    },
    button: {
        padding: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});


class CarCard extends Component {

    state = {
        open: false,
        value: this.props.car.batteryLevel,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleSave = () => {
        this.props.updateBatteryLevel(this.props.car.id, this.state.value)
        this.setState({open: false})
    };


    render() {

        const {classes,
            publicData,
            car, index
        } = this.props;

        const {value} = this.state;

        // Wait until shiftPlans and shifts are loaded
        if(publicData === undefined){
            return (
                <div />
            )
        }

        const users = publicData.users;

        let modifiedByText = '';

        if(car.modifiedBy && users[car.modifiedBy]){
            modifiedByText = `von ${users[car.modifiedBy].firstName}`
        }

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="caption" id="simple-modal-description">
                            {`Shuttle ${car.id}`}
                        </Typography>

                        <Typography variant="h6" id="modal-title">
                            Batterieladezustand eingeben
                        </Typography>

                        <Typography variant="caption">
                            unter Home > Fahrzeug > Laden & Effizienz > Laden
                        </Typography>

                        <Slider
                            classes={{ container: classes.slider }}
                            value={value}
                            min={0}
                            max={100}
                            step={1}
                            aria-labelledby="label"
                            onChange={this.handleChange}
                        />

                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="caption">
                                    {`Ladezustand: ${value}%`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleSave}
                                    className={classes.button}
                                >
                                    Übernehmen
                                </Button>
                            </Grid>
                        </Grid>


                    </div>
                </Modal>

                <Grid item xs={3}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        {index < 3 &&
                        <Grid item>
                            <Tooltip title="Bei Schicht-Ende zum Laden anstecken!">
                                <Avatar className={classes.avatar}>
                                    <FlashOnIcon className={classes.flashIcon}/>
                                </Avatar>
                            </Tooltip>
                        </Grid>
                        }

                        {index >= 3 &&
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                {car.batteryLevel === 100 &&
                                    <BatteryFullIcon className={classes.batteryIcon}/>
                                }
                                {car.batteryLevel < 100 && car.batteryLevel >= 70 &&
                                <Battery80Icon className={classes.batteryIcon}/>
                                }
                                {car.batteryLevel < 70 && car.batteryLevel >= 55 &&
                                <Battery60Icon className={classes.batteryIcon}/>
                                }
                                {car.batteryLevel < 55 && car.batteryLevel >= 40 &&
                                <Battery50Icon className={classes.batteryIcon}/>
                                }
                                {car.batteryLevel < 40 &&
                                <Battery30Icon className={classes.batteryIcon}/>
                                }
                            </Avatar>
                        </Grid>
                        }


                    </Grid>
                </Grid>

                <Grid item xs={7}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography variant="button">
                                {`Shuttle ${car.id}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {`Ladezustand: ${car.batteryLevel}%`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {`Update: ${car.modified.fromNow()} ${modifiedByText}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={2}>
                    <IconButton
                        aria-label="Edit"
                        onClick={this.handleOpen}>
                        <CreateIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );

    }

}

CarCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        publicData: state.firestore.data.public,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBatteryLevel: (carID, batteryLevel) => dispatch(updateBatteryLevel(carID, batteryLevel))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarCard));