import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import moment from 'moment'
import "moment/locale/de";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Local Files
import MainArea from "../layout/MainArea";
import Loading from '../layout/Loading';
import ShiftCard from './ShiftCard';


moment.locale('de');
const styles = theme => ({
    formPaper: {
        width: "100%",
        borderRadius: 0,
        boxShadow: "0px 0px 0px 0px Gainsboro",
    },
    formGroup: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
    },
    formLabel: {
        padding: theme.spacing.unit,
        marginRight: 3 * theme.spacing.unit,
    },


});


function compareShifts(a, b) {
    const startDiff = a.startDate - b.startDate;
    const endDiff = a.endDate - b.endDate;

    return (startDiff === 0) ?  Math.sign(endDiff) : Math.sign(startDiff);
}



class MyShifts extends Component {


    state = {
        showArchivedShifts: false,
    }

    handleSwitchChange = (event) => {

        this.setState({showArchivedShifts: event.target.checked})
    }


    render() {


        const {classes, publicData, initData} = this.props;
        const {showArchivedShifts} = this.state;
        const id = this.props.match.params.id;

        // Wait until the required Data is loaded
        if (publicData === undefined || initData === undefined) {
            return (
                <Loading/>
            )
        }

        const users = publicData.users;
        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        let user = {};
        let myShifts = [];

        //try to get the user object
        try {
            user = users[id];

            let selectedShifts = user.selectedShifts ? user.selectedShifts : []

            selectedShifts.map(shiftID => {

                //try to load the user's shifts
                try {

                    let shift = shifts[shiftID];

                    myShifts.push({
                        id: shiftID,
                        startDate: moment(shift.startDate.seconds * 1000),
                        endDate: moment(shift.endDate.seconds * 1000),
                        drivers: shift.drivers,
                        selectedDriver: shift.selectedDriver,

                    })
                } catch (e) {
                    console.log('shift can not be loaded correctly', shiftID, shifts[shiftID], e)
                }
            })

        } catch (e) {
            console.log('user can not be loaded correctly', id, users[id], e)
        }

        //user does not exist or could not be loaded
        if (user === undefined) {
            return (
                <Redirect to='/myshifs-user-not-found'/>
            );
        }

        //myShifts has all shift objects where the user is the selected driver
        myShifts = myShifts.sort(compareShifts);

        //all selected shifts of the user starting today
        let myShiftsFromToday = myShifts.filter((shift) => {
            return moment().diff(shift.startDate,'days') <= 0
        });



        return (

            <MainArea>

                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Paper className={classes.formPaper}>
                            <FormGroup className={classes.formGroup}>
                                <FormControlLabel
                                    label={
                                        <Typography
                                            color='inherit'
                                            variant='body1'
                                            gutterBottom
                                        >
                                            zurückliegende Schichten einblenden
                                        </Typography>}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={this.state.showArchivedShifts}
                                            value="showArchiv"
                                            onChange={this.handleSwitchChange}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Paper>
                    </Grid>

                    {((!showArchivedShifts && myShiftsFromToday.length === 0) ||
                    (showArchivedShifts && myShifts.length === 0)) &&
                        <Paper className={classes.formPaper}>
                            <Typography
                                color='inherit'
                                variant='body1'
                                gutterBottom
                            >
                                Es gibt noch keine Schichten, die angezeigt werden können.
                            </Typography>
                        </Paper>
                    }

                    {!showArchivedShifts && myShiftsFromToday.map((shift, index) => (
                        <Grid
                            item xs={12}
                            key={shift.id}
                        >
                            <ShiftCard
                                shiftID={shift.id}
                                mode='view'
                                avatarText={shift.startDate.format('dd')}
                                showDate={true}
                            />
                        </Grid>
                    ))}

                    {showArchivedShifts && myShifts.map((shift, index) => (
                        <Grid
                            item xs={12}
                            key={shift.id}>
                            <ShiftCard
                                shiftID={shift.id}
                                mode='view'
                                avatarText={shift.startDate.format('dd')}
                                showDate={true}
                            />
                        </Grid>
                    ))}

                </Grid>

            </MainArea>
        );

    }

}

MyShifts.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(MyShifts);