import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import classnames from 'classnames';
import {compose} from "redux";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Collapse from '@material-ui/core/Collapse';
import CardHeader from '@material-ui/core/CardHeader';




// Local Files
import ShiftCardHeader from "./ShiftCardHeader";
import UserChip from "../user/UserChip";
import { applyShift, unapplyShift, selectDriver } from '../../store/actions/shiftActions';
import Authorization from "../auth/Authorization";
import ChooseUserPanel from "../user/ChooseUserPanel";


moment.locale('de');
const styles = theme => ({
    card: {
        width: "100%",
        borderRadius: 0,
        marginTop: 1,
        boxShadow: "0px -1px 0px 0px Gainsboro",

    },
    cardHeader: {
        paddingBottom : 0,
    },
    cardContent:{
        paddingTop: 0,
        paddingBottom: 0,
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    icon: {
        paddingRight: 5,
    },
    chip: {
        margin: theme.spacing.unit,
        width: "100%",
        justifyContent: 'left',
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
        align: 'right',
    },
    gridItemLeft:{
        marginLeft: 'auto',
    }

});

/*
    props:
        avatarText: if set, the text will be displayed in the avatar
        shiftID: ID of the displayed shift
        mode:
            'edit' role specific edit functions
            'view' role specific view
        showDate: true shows formatted Date String
*/

class ShiftCard extends Component {

    state = {
        dataLoaded: false,
        expanded: false,
        chosenUser: null,
    }


    handleDriverClick = (e) => {

        if (this.state.selectedDriver === e.target.id) {
            console.log("driver already selected")
        } else {
            this.props.selectDriver(this.props.shiftID, e.target.id)
        }
    }

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    handleApplyClick = () => {

        // check applyClicked to ensure that just on click happens one at a time
        if(!this.state.applyClicked){

            if(!this.state.hasApplied){
                this.props.applyShift(this.props.shiftID);
            }
            else
            {
                this.props.unapplyShift(this.props.shiftID);
            }

            this.setState({
                applyClicked: true
            })


        }

    };

    handleAddChosenUserClick = () => {
        const {chosenUser} = this.state;

        if(chosenUser !== null){

            if (this.state.selectedDriver === chosenUser) {
                console.log("driver already selected")
            } else {
                this.props.selectDriver(this.props.shiftID, chosenUser)
            }

        }
    }

    render() {

        const {classes,
            avatarText, shiftID, mode, showDate,
            auth, publicData, initData} = this.props;

        if(publicData === undefined || auth === undefined || initData === undefined){
            return( <Card className={classes.card}/>)
        }

        const users = publicData.users;
        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        //try to load the user's shifts
        let myShift = null;

        try {

            let shift = shifts[shiftID];

            myShift = {
                id: shiftID,
                startDate: moment(shift.startDate.seconds * 1000),
                endDate: moment(shift.endDate.seconds * 1000),
                drivers: shift.drivers? shift.drivers: [],
                selectedDriver: shift.selectedDriver,
            }

            this.state = {
                ...this.state,
                selectedDriver: shift.selectedDriver,
            }

        } catch (e) {
            console.log('shift can not be loaded correctly', shiftID, e)
        }

        if(myShift === null){
            return( <Card className={classes.card}/>)
        }


        // get the driver objects of this shift
        let myDrivers = [];

        myShift.drivers.map(driverID => {

            //try to load the user's shifts
            try {

                let user = users[driverID];

                myDrivers.push({
                    id: driverID,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    selectedShifts: user.selectedShifts? user.selectedShifts: [],
                    requestedShifts: user.requestedShifts? user.requestedShifts: [],

                })
            } catch (e) {
                console.log('user can not be loaded correctly', driverID, e)
            }
        })

        // get the selected driver objects of this shift
        let mySelectedDriver = null;

        try{
            if (myShift.selectedDriver !== ''){
                let user = users[ myShift.selectedDriver ];
                mySelectedDriver = {
                    id: myShift.selectedDriver,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    selectedShifts: user.selectedShifts? user.selectedShifts: [],
                    requestedShifts: user.requestedShifts? user.requestedShifts: [],
                }
            }
        }catch (e) {
            console.log('selected driver user can not be loaded correctly', myShift.selectedDriver, e)
        }


        return (

            <Card className={!showDate && classes.card}>
                {showDate &&
                    <CardHeader
                        className={classes.cardHeader}

                        title={
                            <Typography variant="h5">
                                {`KW ${myShift.startDate.week()} - ${myShift.startDate.format('Do MMMM YYYY')}`}
                            </Typography>
                        }
                    />
                }
                <ShiftCardHeader
                    avatarText={avatarText}
                    shift={myShift}
                    mode={mode}
                />

                {!showDate &&
                <CardContent className={classes.cardContent}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>{
                            mySelectedDriver &&
                            <UserChip user={mySelectedDriver}/>
                        }

                        </Grid>

                        <Authorization>
                            <Grid item xs={2} className={classes.gridItemLeft}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Grid>
                                        <IconButton
                                            className={classnames(classes.expand, {
                                                [classes.expandOpen]: this.state.expanded,
                                            })}
                                            onClick={this.handleExpandClick}
                                            aria-expanded={this.state.expanded}
                                            aria-label="Show more"
                                        >
                                            <Typography
                                                className={classes.secondaryHeading}
                                            >
                                                {myShift.drivers.length}
                                            </Typography>
                                            <ExpandMoreIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Authorization>
                    </Grid>
                </CardContent>}

                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={10}>
                                <ChooseUserPanel onChange={(e) => {this.setState({chosenUser: e.target.value})}}/>
                            </Grid>

                            <Grid item xs={2}>
                                <IconButton
                                    onClick={this.handleAddChosenUserClick}
                                    aria-label="Select User"
                                >
                                    <PersonAddIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>

                        {myDrivers.map((driver, index) => (
                            <UserChip
                                key={driver.id}
                                user={driver}
                                color={driver.id === myShift.selectedDriver ? "secondary" : "primary"}
                                onClick={this.handleDriverClick}
                            />

                        ))}
                    </CardContent>
                </Collapse>
            </Card>

        );

    }

}

ShiftCard.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
        applyShift: (shiftID) => dispatch(applyShift(shiftID)),
        unapplyShift: (shiftID) => dispatch(unapplyShift(shiftID)),
        selectDriver: (shiftID, selectedDriver) => dispatch(selectDriver(shiftID, selectedDriver)),
    }
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ShiftCard);

