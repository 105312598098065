// React
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

// material-ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Hidden from '@material-ui/core/Hidden';

// Own
import { signOut } from '../../store/actions/authActions'

const TopLogo = '//www.kiwilabs.de/kiwi_top_logo/'

const styles = theme => ({
    root: {
        flexGrow: 1,

    },
    appBar: {
        top: 0,
        bottom: 'auto',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: 20,
        marginRight: 20,
        padding: 2,
        flex: 1,
    }
});

class TopNav extends React.Component {

    state = {
        anchorEl: null,
        showMenu: false,
        displayedTitle: 'VIP Shuttle',
    }

    setDisplayedTitle = () => {

        const {pathname} = this.props.location;
        const pageName = pathname.split('/')[1];
        const breadcrums =  pathname.split('/')[2];

        let titel = '';

        switch(pageName) {
            //USER
            case 'createuser':
                titel = 'Nutzer erstellen';
                break;
            case 'viewuser':
                titel = 'Profil';
                break;
            case 'edituser':
                titel = 'Profil ändern';
                break;
            //SHIFTPLAN
            case 'createshiftplan':
                titel = 'Schichtplan erstellen';
                break;
            case 'editshiftplan':
                titel = 'Schichtplan bearbeiten';
                break;
            case 'viewshiftplan':
                if(breadcrums === undefined){
                    titel = 'Schichtpläne';
                }
                else {
                    titel = 'Schichtplan';
                }

                break;
            default:
                titel = 'VIP Shuttle';
        }

        this.state.displayedTitle = titel
    };

    handleMenuOpen = event => {
        this.setState({
            showMenu: true,
            anchorEl: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            showMenu: false,
        });
    }

    handleClick = (e) => {

        this.handleMenuClose()

        switch(e.target.id) {
            case "Profile":
                const userId = this.props.auth.uid;
                this.props.history.push("/viewuser/"+userId);
                break;
            case "Logout":
                this.props.signOut();
                break;
            default:
            // nothing
        }
    }


    render() {
        const { classes, auth } = this.props;
        const { anchorEl, showMenu } = this.state;

        this.setDisplayedTitle();

        return (
            <div className={classes.root}>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <Hidden xsDown>
                            <IconButton className={classes.menuButton}>
                                <img
                                    src={TopLogo}
                                    height='48'
                                    alt=''
                                />
                            </IconButton>
                        </Hidden>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {this.state.displayedTitle}
                        </Typography>
                        {auth.uid && (
                            <div>
                                <IconButton
                                    aria-owns={'menu-appbar'}
                                    aria-haspopup="true"
                                    onClick={this.handleMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={showMenu}
                                    onClose={this.handleMenuClose}
                                >
                                    <MenuItem id="Profile" onClick={this.handleClick}>Profil</MenuItem>
                                    <MenuItem id="Logout" onClick={this.handleClick}>Logout</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

TopNav.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopNav)));