
export const editUser = (user, id) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const db = firebase.firestore();

        let userPromises = [];

        // Update user data
        const userPromise = db.collection("public").doc('users').update({
            [id + '.firstName'] : user.firstName,
            [id + '.lastName'] : user.lastName,
            [id + '.phone'] : user.phone,
            [id + '.employeeID'] : parseInt(user.employeeID),
        });

        // Update user data
        const additionalDataPromise = db.collection("protected").doc('userDetails').set({
            [id] : {
                'address': user.address,
                'city': user.city,
                'zip': user.zip,
                'iban': user.iban,
                'bic': user.bic,
                'bank': user.bank,
                'birthday': firebase.firestore.Timestamp.fromDate(user.birthday),
                'socialSecNr': user.socialSecNr,
            }
        });

        userPromises.push(userPromise, additionalDataPromise)

        Promise.all(userPromises).then(() => {
            console.log("EDIT_USER_SUCCESS");
            dispatch({ type: 'EDIT_USER_SUCCESS' });
        }).catch( err => {
            console.log("EDIT_USER_ERROR");
            dispatch({ type: 'EDIT_USER_ERROR', err });
        });
    }
};
