import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {Line} from 'react-chartjs-2';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/es/Paper/Paper";

// Local Files

moment.locale('de');

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    div:{
        margin: 2 * theme.spacing.unit,
    }
});

let chart_data = {
    labels: [],
    datasets: [
        {
            label: 'Gesamtstunden',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
        }
    ]
};

class TotalHoursPanel extends Component {




    render(){

        const {classes,
            publicData, initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(publicData === undefined || initData === undefined){
            return (
                <Paper>
                    Loading...
                </Paper>
            )
        }

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);


        let myShifts = [];

        for(let shiftID in shifts) {
            //try to load the shifts
            try {

                let shift = shifts[shiftID];

                myShifts.push({
                    id: shiftID,
                    startDate: moment(shift.startDate.seconds * 1000),
                    endDate: moment(shift.endDate.seconds * 1000),
                    drivers: shift.drivers ? shift.drivers : [],
                    selectedDriver: shift.selectedDriver,
                    shiftPlanID: shift.shiftPlanID
                })
            } catch (e) {
            }
        }
        // Calc the displayed data
        let data = {};

        myShifts.map(shift => {
            const month = shift.startDate.format("MMM YY");

            if (data[month] === undefined) {
                data[month] = {
                    count: 0,
                    total: 0,
                }
            }
            //Update the count and total hours of a month
            data[month].count += 1;
            data[month].total += shift.endDate.diff(shift.startDate, 'hours', true);
        })

        const dataKeys =  Object.keys(data).sort((a, b) => {
            return moment(a, 'MMM YY').isAfter(moment(b, 'MMM YY'))
        })

        for(let i = 0; i < dataKeys.length; i++){
            chart_data.datasets[0].data[i] = data[dataKeys[i]].total
        }

        chart_data.labels = dataKeys;



        return (

            <Paper className={classes.root}>
                <div className={classes.div}>
                    <Line data={chart_data} />
                </div>

            </Paper>

        );

    }

}

TotalHoursPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(TotalHoursPanel) );