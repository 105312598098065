import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import "moment/locale/de";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Local Files
import MainArea from "../layout/MainArea";
import DatabaseCheckCard from "../admin/DatabaseCheckCard";
import DriverMonthReportCard from "../admin/DriverMonthReportCard";
import CarPanel from "./CarPanel";
import WorkingHoursPanel from "./WorkingHoursPanel";
import TotalHoursPanel from "./TotalHoursPanel";
import {getFirebase} from "react-redux-firebase";
import DriverWorkdaysReportCard from "../admin/DriverWorkdaysReportCard";




const styles = theme => ({
    button: {
        padding: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    expansionPanel : {
        padding: 0,
        borderRadius: 0,
        boxShadow: "0px 0px",
    },
    heading : {
       marginBottom: 2*theme.spacing.unit,
    }


});



class AdminDashboard extends Component {


    state = {
        createRedirect: false,
    };

    handleCreate = () => {
        this.setState({
            createRedirect: true
        })
    };

    handleArchive = () => {
        const firebase = getFirebase();

        let addMessage = firebase.app().functions('europe-west1').httpsCallable('archiveArchive');
        addMessage({}).then(() => console.log("done"))
    };


    render() {


        const {classes} = this.props;

        if(this.state.createRedirect){
            return (
                <Redirect to='/createuser'/>
            )
        }


        return (

            <MainArea>

                <Typography className={classes.heading} variant="h6" >
                    Admin Dashboard
                </Typography>


                <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Gesamtstunden</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <TotalHoursPanel/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Übersicht Arbeitszeit</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <DriverMonthReportCard/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Übersich Arbeitstage</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>

                        <DriverWorkdaysReportCard/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>E-tron Ladestatus</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <CarPanel/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Database Check</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <DatabaseCheckCard/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Meine Arbeitsstunden</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <WorkingHoursPanel/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Extras</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanel}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this.handleCreate}
                            className={classes.button}
                        >
                            Neuen User anlegen
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this.handleArchive}
                            className={classes.button}
                        >
                            Archive
                        </Button>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </MainArea>
        );

    }

}

AdminDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(AdminDashboard);