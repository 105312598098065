const initState = {
}

const shiftPlanReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_SHIFTPLAN_SUCCESS':
            return state;
        case 'UPDATE_SHIFTPLAN_ERROR':
            return state;
        case 'CREATE_SHIFTPLAN_SUCCESS':
            return state;
        case 'CREATE_SHIFTPLAN_ERROR':
            return state;
        case 'LOAD_ARCHIVE_SHIFTPLAN_SUCCESS':
            return {
                ...state,
                archive: action.shifts
            }
        default:
            return state;
    }
};

export default shiftPlanReducer;