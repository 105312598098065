import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment'
import "moment/locale/de";
import {compose} from "redux";
import {withRouter} from "react-router-dom";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Local Files
import MainArea from '../layout/MainArea'
import Loading from '../layout/Loading'
import {createShiftPlan} from '../../store/actions/shiftPlanActions'

const HappyChildImage = '//www.kiwilabs.de/happychild/'

moment.locale('de');

const styles = theme => ({});

const NR_OF_WEEKS_PREPLAN = 4;
const MAX_NR_DISPLAYED_TEMPLATES = 8;

function compareShiftPlans(a, b) {
    if (a.startDate < b.startDate)
        return -1;
    if (a.startDate > b.startDate)
        return 1;
    return 0;
}

const getNextUnplannedMondys = (myShiftPlans) => {

    // Will contain the next unplanned weeks
    let nextWeeks = [];

    //Calc the next unplanned weeks
    [...Array(NR_OF_WEEKS_PREPLAN)].map((x, i) => {

        let contained = false;
        let week = moment()
            .day(i * 7 + 7)
            .day('Montag')
            .hour(12)
            .minute(0)
            .second(0)
            .millisecond(0);

        myShiftPlans.map((plan) => {

            if (moment(plan.startDate).isSame(week, 'w')) {
                contained = true;
            }
        })

        if (!contained) nextWeeks.push(week)

    })

    return nextWeeks
}

const genShiftPlanTemplates = (myShiftPlans) => {

    // will contain the templates
    let templates = []

    for (let i = 0; (i < MAX_NR_DISPLAYED_TEMPLATES) && i < myShiftPlans.length; i++) {
        templates.push(myShiftPlans[i])
    }

    return templates

}

class CreateShiftPlan extends Component {

    state = {

        nextShiftPlanID: 0,
        templateID: -1,
        dataGenerated: false,

    }


    handleChange = event => {

        this.setState({[event.target.name]: event.target.value});
    };

    handleClose = event => {
        this.setState({[event.target.name + 'Open']: false});
    };

    handleOpen = event => {
        this.setState({[event.target.name + 'Open']: true});
    };

    handleSubmit = event => {

        const {nextWeeks, templates, nextShiftPlanID, templateID} = this.state;

        let newShiftPlan = {
            startDate: moment(nextWeeks[nextShiftPlanID]).toDate(),
            status: 0,
            shifts: [],
        }

        if (templateID === -1) {
            // Create without a Template (without any shifts)
            console.log('creating without', newShiftPlan)
            this.props.createShiftPlan(newShiftPlan, '')

            this.props.history.push('/viewshiftplan');

        } else {
            // Create from a Template
            const template = templates[templateID];

            console.log('creating with', newShiftPlan, template)

            this.props.createShiftPlan(newShiftPlan, template.id)
            this.props.history.push('/viewshiftplan');

        }

    }

    render() {

        const {publicData} = this.props;

        const {nextWeeks, templates} = this.state;



        if (publicData === undefined) {
            return (
                <Loading/>
            )
        }

        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        //shiftPlans is a nested object and shall be convert to an array
        let myShiftPlans = []

        for(let shiftPlanID in shiftPlans) {

            //try to load the shiftplan
            try {

                let shiftPlan = shiftPlans[shiftPlanID];

                myShiftPlans.push({
                    id: shiftPlanID,
                    startDate: moment(shiftPlan.startDate.seconds * 1000),
                    state: shiftPlan.state,
                    shifts: shiftPlan.shifts? shiftPlan.shifts: [],

                })
            } catch (e) {
                console.log('shiftplan can not be loaded correctly', shiftPlanID, e)
            }
        }

        myShiftPlans = myShiftPlans.sort(compareShiftPlans).reverse()

        if(!this.state.dataGenerated){
            let w = getNextUnplannedMondys(myShiftPlans);
            let t = genShiftPlanTemplates(myShiftPlans);

            this.setState({
                nextWeeks: w,
                templates: t,
                dataGenerated: true,
            })
        }

        // Wait until the next weeks and the templates are there
        if (!nextWeeks || !templates) {
            return (
                <Loading/>
            )
        }

        // If all future weeks are already planned
        if (nextWeeks.length === 0) {

            return (
                <MainArea>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <img src={HappyChildImage} width='100%' alt=''/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5" padding="20">
                                Klasse!
                            </Typography>
                            <Typography variant="inherit" padding="20">
                                {`Die Schichtpläne für die nächsten ${NR_OF_WEEKS_PREPLAN} Wochen sind bereits alle angelegt.`}
                            </Typography>
                        </Grid>
                    </Grid>
                </MainArea>
            )
        }

        return (
            <MainArea>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" padding="20">
                            Neuer Wochenplan anlegen
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="inherit" padding="20">
                            für die Kalenderwoche
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            open={this.state.nextShiftPlanIDOpen}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            value={this.state.nextShiftPlanID}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'nextShiftPlanID',
                                id: 'nextShiftSelect',
                            }}
                        >

                            {nextWeeks.map((week, index) => (
                                <MenuItem value={index} key={'next' + index}>
                                    {`KW ${week.week()}
                                            (${week.format('L')} -
                                            ${moment(week).day(7).format('L')})`}
                                </MenuItem>
                            ))}


                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="inherit" padding="20">
                            mit der Vorlage
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            open={this.state.templateIDOpen}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            value={this.state.templateID}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'templateID',
                                id: 'shiftTemplateSelect',
                            }}
                        >
                            <MenuItem value={-1}>
                                <em>ohne Vorlage</em>
                            </MenuItem>
                            {templates.map((shiftPlan, index) => {

                                let startDate = shiftPlan.startDate

                                return (
                                    <MenuItem value={index} key={'t' + index}>
                                        {`KW ${startDate.week()}
                                            (${startDate.format('L')} -
                                            ${moment(startDate).day(7).format('L')})`}
                                    </MenuItem>
                                )

                            })}

                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                        >
                            Neu anlegen
                        </Button>
                    </Grid>
                </Grid>

            </MainArea>
        );

    }

}

CreateShiftPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createShiftPlan: (shiftPlan, copyShiftPlanID) => dispatch(createShiftPlan(shiftPlan, copyShiftPlanID))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(CreateShiftPlan);