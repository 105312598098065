import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from "moment";
import "moment/locale/de";
import {compose} from "redux";
import {firestoreConnect, getFirebase} from "react-redux-firebase";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


// Local Files

moment.locale("de");

const styles = theme => ({
    root: {
        flexGrow: 1,
    },

    gridContainer: {
        spacing: 24,
        alignItems: "center",
        justify: "center"

    },
    section1: {
        margin: `${theme.spacing.unit * 3}px 0`,
    },
});

class ViewAdditionalUser extends Component {

    state = {
        myUser: null,
    }


    render() {
        const {classes} = this.props;
        const {myUser} = this.state;

        const userID = this.props.id;

        // This is how Admin load the Data
        if (myUser === null) {

            // Load the data from the DB

            const firebase = getFirebase();
            const db = firebase.firestore();

            let docRef = db.collection("protected").doc('userDetails');

            docRef.get().then((doc) => {
                if (doc.exists) {
                    const user = doc.data()[userID];
                    let myUser = {};

                    try {
                        myUser = {
                            id: userID,
                            address: user.address,
                            bank: user.bank,
                            bic: user.bic,
                            birthday: moment(user.birthday.seconds * 1000),
                            city: user.city,
                            iban: user.iban,
                            socialSecNr: user.socialSecNr,
                            zip: user.zip,
                        }

                        this.setState({myUser: myUser});

                    } catch (e) {
                        console.log('user can not be loaded correctly', userID, e)
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such user document!");
                }
            }).catch((e) => {

            });
        }

        if (myUser === null) {

           // Load the data from the DB

           const firebase = getFirebase();

           let getUserDetails = firebase.app()
               .functions('europe-west1').httpsCallable("userGetUserDetails");

           getUserDetails().then( (res) => {

               const user = res.data;
               let myUser = {};


               try {
                   myUser = {
                       id: userID,
                       address: user.address,
                       bank: user.bank,
                       bic: user.bic,
                       birthday: moment(user.birthday._seconds * 1000),
                       city: user.city,
                       iban: user.iban,
                       socialSecNr: user.socialSecNr,
                       zip: user.zip,
                   }

                   this.setState({myUser: myUser});

               } catch (e) {
                   console.log('user can not be loaded correctly', userID, e)
               }

           }).catch((e) => {
               console.error("Error reading userDetails document: ", e);
           });
       }

        if (myUser === null) return <div/>

        return (
            <div className={classes.root}>
                <Divider variant="middle"/>
                <div className={classes.section1}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Geburtsdatum:
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                {myUser.birthday.format('Do MMM YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider variant="middle"/>
                <div className={classes.section1}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Sozialversicherung:
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                {myUser.socialSecNr}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider variant="middle"/>
                <div className={classes.section1}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Anschrift:
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                {myUser.address}
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                {myUser.zip + ' ' + myUser.city}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider variant="middle"/>
                <div className={classes.section1}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Bankdaten:
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                IBAN: {myUser.iban}
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                BIC: {myUser.bic}
                            </Typography>
                            <Typography gutterBottom variant="inherit">
                                Institut: {myUser.bank}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );

    }

}

ViewAdditionalUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {}

}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(ViewAdditionalUser);
