import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import moment from 'moment'
import "moment/locale/de";

// Material UI
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Local Files
import MainArea from "../layout/MainArea";
import Loading from '../layout/Loading';
import ShiftCard from "../shift/ShiftCard";
import ShiftPlanCard from '../shiftplan/ShiftPlanCard';
import CarPanel from "./CarPanel";
import WorkingHoursPanel from "./WorkingHoursPanel";


moment.locale('de');
const styles = theme => ({

    formPaper: {
        width: "100%",
        borderRadius: 0,
        boxShadow: "0px 0px 0px 0px Gainsboro",
    },
    gridItemWithTop: {
        marginTop: 3*theme.spacing.unit,
    }

});

function compareShifts(a, b) {
    const startDiff = a.startDate - b.startDate;
    const endDiff = a.endDate - b.endDate;

    return (startDiff === 0) ?  Math.sign(endDiff) : Math.sign(startDiff);
}

function compareShiftPlans(a, b) {
    if (a.startDate < b.startDate)
        return -1;
    if (a.startDate > b.startDate)
        return 1;
    return 0;
}


class DriverDashboard extends Component {


    state = {};


    render() {


        const {classes,
            auth, publicData, initData} = this.props;


        if(auth === undefined || publicData === undefined || initData === undefined){
            return (
                <Loading />
            )
        }

        const users = publicData.users;
        const shiftPlans = this.props._shiftPlan.archive ? this.props._shiftPlan.archive: {};
        Object.assign(shiftPlans, publicData.shiftPlans);

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        let user = null;
        let myShifts = [];

        //try to get the user object
        try {
            user = users[auth.uid];

            let selectedShifts = user.selectedShifts ? user.selectedShifts : []

            selectedShifts.map(shiftID => {

                //try to load the user's shifts
                try {

                    let shift = shifts[shiftID];

                    myShifts.push({
                        id: shiftID,
                        startDate: moment(shift.startDate.seconds * 1000),
                        endDate: moment(shift.endDate.seconds * 1000),
                        drivers: shift.drivers,
                        selectedDriver: shift.selectedDriver,

                    })
                } catch (e) {
                    console.log('shift can not be loaded correctly', shiftID, shifts[shiftID], e)
                }
            })

        } catch (e) {
            console.log('user can not be loaded correctly', auth.uid, e)
        }

        //user does not exist or could not be loaded
        if (user === null) {
            return (
                <Redirect to='/driver-dashboard-user-not-found'/>
            );
        }

        //myShifts has all shift objects where the user is the selected driver
        // Filter the shifts that the current shift (until 1h after shift end) and the future once are included
        myShifts = myShifts.filter((shift) => {
            return moment().diff(shift.endDate,'hours') <= 1
        }).sort(compareShifts);


        //shiftPlans is a nested object and shall be convert to an array
        let myShiftPlans = []

        for(let shiftPlanID in shiftPlans) {

            //try to load the shiftplan
            try {

                let shiftPlan = shiftPlans[shiftPlanID];

                myShiftPlans.push({
                    id: shiftPlanID,
                    startDate: moment(shiftPlan.startDate.seconds * 1000),
                    state: shiftPlan.state,
                    shifts: shiftPlan.shifts? shiftPlan.shifts: [],

                })
            } catch (e) {
                console.log('shiftplan can not be loaded correctly', shiftPlanID, e)
            }
        }

        let today = moment();

        myShiftPlans = myShiftPlans.filter((shiftPlan) => {
            return shiftPlan.startDate.isSameOrAfter(today, 'week')
        }).sort(compareShiftPlans)

        console.log(myShiftPlans)


        return (

            <MainArea>

                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Paper className={classes.formPaper}>
                            <Typography
                                color='inherit'
                                variant='h5'
                                gutterBottom
                            >
                                Meine nächste Schicht:
                            </Typography>
                            {myShifts.length === 0 &&
                                <Typography
                                    color='inherit'
                                    variant='body1'
                                    gutterBottom
                                >
                                    Du bist bisher noch zu keiner Schicht eingeteilt.
                                </Typography>
                            }
                        </Paper>

                    </Grid>

                    {myShifts.length !== 0 &&
                    <Grid item xs={12}>
                        <ShiftCard
                            shiftID={myShifts[0].id}
                            mode='view'
                            avatarText={myShifts[0].startDate.format('dd')}
                            showDate={true}
                        />
                    </Grid>
                    }

                    {myShifts.length !== 0 && moment().isSame(myShifts[0].startDate, 'day') &&
                    <Grid item xs={12}>
                        <CarPanel />
                    </Grid>
                    }

                    {myShifts.length !== 0 &&
                    <Grid item xs={12}>
                        <Paper className={classes.formPaper}>
                            <Typography
                                color='inherit'
                                variant='h5'
                                gutterBottom
                            >
                                Meine Stundenübersicht:
                            </Typography>
                        </Paper>

                        <WorkingHoursPanel />
                    </Grid>
                    }

                    <Grid item xs={12} className={classes.gridItemWithTop}>
                        <Paper className={classes.formPaper}>
                            <Typography
                                color='inherit'
                                variant='h5'
                                gutterBottom
                            >
                                Aktueller Schichtplan
                            </Typography>
                            {myShiftPlans.length === 0 &&
                            <Typography
                                color='inherit'
                                variant='body1'
                                gutterBottom
                            >
                                Bisher ist noch kein aktueller Schichtplan erstellt.
                            </Typography>
                            }
                        </Paper>

                    </Grid>

                    {myShiftPlans.length !== 0 &&
                    <Grid item xs={12}>
                        <ShiftPlanCard
                            shiftPlanID={myShiftPlans[0].id}
                        />
                    </Grid>
                    }

                </Grid>

            </MainArea>
        );

    }

}

DriverDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(DriverDashboard);