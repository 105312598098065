import {getFirebase} from "react-redux-firebase";

export const createShift = (shiftPlanID, newShift, shiftPlanState) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        let shiftRef = shiftPlanState === 3 ?
            fs.collection("public").doc("shifts") :
            fs.collection("init").doc("shifts");

        let shiftPlanRef = fs.collection("public").doc("shiftPlans");

        const shiftID = shiftPlanID +  Date.now().toString(16).substring(5);

        let myShift = {
            creator: getState().firebase.auth.uid,
            created: firebase.firestore.Timestamp.now(),
            startDate: firebase.firestore.Timestamp.fromDate(newShift.startDate),
            endDate: firebase.firestore.Timestamp.fromDate(newShift.endDate),
            drivers: [],
            selectedDriver: '',
            shiftPlanID: shiftPlanID,
        };

        // Get a new write batch
        let batch = fs.batch();

        batch.update(shiftRef,{[shiftID]: myShift});

        batch.update(shiftPlanRef, {
            [shiftPlanID + ".shifts"]: firebase.firestore.FieldValue.arrayUnion(shiftID)
        });

        batch.commit()
            .then(() => {
            dispatch({type: 'CREATE_SHIFT_SUCCESS'});
            console.log('CREATE_SHIFT_SUCCESS')
            }).catch(error => {
            dispatch({type: 'CREATE_SHIFT_ERROR'}, error);
            console.log('CREATE_SHIFT_ERROR')
            })
    }
};

export const deleteShift = (shiftID) => {
    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();
        const fs = firebase.firestore();

        const publicShifts = getState().firestore.data.public.shifts;
        const initShifts = getState().firestore.data.init.shifts;

        console.log(shiftID in publicShifts, shiftID in initShifts)

        if (shiftID in publicShifts) {

            let shiftRef = fs.collection("public").doc("shifts");
            let shiftPlanRef = fs.collection("public").doc("shiftPlans");
            let userRef = fs.collection("public").doc("users");

            const shiftPlanID = publicShifts[shiftID].shiftPlanID;
            const selectedDriver = publicShifts[shiftID].selectedDriver;


            // Get a new write batch
            let batch = fs.batch();

            batch.update(shiftRef,{
                [shiftID]: firebase.firestore.FieldValue.delete()
            });

            batch.update(shiftPlanRef, {
                [shiftPlanID + ".shifts"]: firebase.firestore.FieldValue.arrayRemove(shiftID)
            });


            if (selectedDriver !== undefined && selectedDriver !== ''){
                batch.update(userRef, {
                    [selectedDriver + ".selectedShifts"]: firebase.firestore.FieldValue.arrayRemove(shiftID)
                });
            }



            batch.commit()
                .then(() => {
                    dispatch({type: 'DELETE_SHIFT_SUCCESS'});
                    console.log('DELETE_SHIFT_SUCCESS')
                }).catch(error => {
                dispatch({type: 'DELETE_SHIFT_ERROR'}, error);
                console.log('DELETE_SHIFT_ERROR')
            })

        }

        if (shiftID in initShifts) {

            let shiftRef = fs.collection("init").doc("shifts");
            let shiftPlanRef = fs.collection("public").doc("shiftPlans");

            const shiftPlanID = initShifts[shiftID].shiftPlanID;

            // Get a new write batch
            let batch = fs.batch();

            batch.update(shiftRef,{
                [shiftID]: firebase.firestore.FieldValue.delete()
            });

            batch.update(shiftPlanRef, {
                [shiftPlanID + ".shifts"]: firebase.firestore.FieldValue.arrayRemove(shiftID)
            });



            batch.commit()
                .then(() => {
                    dispatch({type: 'DELETE_SHIFT_SUCCESS'});
                    console.log('DELETE_SHIFT_SUCCESS')
                }).catch(error => {
                dispatch({type: 'DELETE_SHIFT_ERROR'}, error);
                console.log('DELETE_SHIFT_ERROR')
            })
        }
    }

};

export const selectDriver = (shiftID, selectedDriver) => {
    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();
        const fs = firebase.firestore();

        const publicShifts = getState().firestore.data.public.shifts;
        const initShifts = getState().firestore.data.init.shifts;

        console.log(shiftID in publicShifts, shiftID in initShifts)

        // Only set selected Driver when shift is already published
        if (shiftID in publicShifts) {

            const myShift = publicShifts[shiftID]
            const oldselectedDriver = myShift.selectedDriver;

            let shiftRef = fs.collection("public").doc("shifts");
            let userRef = fs.collection("public").doc("users");

            // Get a new write batch
            let batch = fs.batch();

            batch.update(shiftRef,{
                [shiftID + ".selectedDriver"]: selectedDriver
            });

            batch.update(userRef, {
                [selectedDriver + ".selectedShifts"]: firebase.firestore.FieldValue.arrayUnion(shiftID)
            });

            if (oldselectedDriver !== undefined && oldselectedDriver !== ''){
                batch.update(userRef, {
                    [oldselectedDriver + ".selectedShifts"]: firebase.firestore.FieldValue.arrayRemove(shiftID)
                });
            }

            batch.commit()
                .then(() => {
                    dispatch({type: 'SELECTDRIVER_SHIFT_SUCCESS'});
                    console.log('SELECTDRIVER_SHIFT_SUCCESS')
                }).catch(error => {
                dispatch({type: 'SELECTDRIVER_SHIFT_ERROR'}, error);
                console.log('SELECTDRIVER_SHIFT_ERROR')
            })

        }

        if (shiftID in initShifts) {

            let shiftRef = fs.collection("init").doc("shifts");

            shiftRef.update({
                [shiftID + ".selectedDriver"]: selectedDriver
            }).then(() => {
                    dispatch({type: 'SELECTDRIVER_SHIFT_SUCCESS'});
                    console.log('SELECTDRIVER_SHIFT_SUCCESS')
                }).catch(error => {
                dispatch({type: 'SELECTDRIVER_SHIFT_ERROR'}, error);
                console.log('SELECTDRIVER_SHIFT_ERROR')
            })


        }

    }
};

export const applyShift = (shiftID) => {
    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();
        const fs = firebase.firestore();

        let shiftRef = fs.collection("init").doc("shifts");

        shiftRef.update({
            [shiftID + ".drivers"]: firebase.firestore.FieldValue.arrayUnion(getState().firebase.auth.uid)
        }).then(() => {
            dispatch({type: 'APPLY_SHIFT_SUCCESS'});
        }).catch(error => {
            dispatch({type: 'APPLY_SHIFT_ERROR'}, error);
        })
    }
};

export const unapplyShift = (shiftID) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        let shiftRef = fs.collection("init").doc("shifts");

        shiftRef.update({
            [shiftID + ".drivers"]: firebase.firestore.FieldValue.arrayRemove(getState().firebase.auth.uid)
        }).then(() => {
            dispatch({type: 'UNAPPLY_SHIFT_SUCCESS'});
        }).catch(error => {
            dispatch({type: 'UNAPPLY_SHIFT_ERROR'}, error);
        })
    }
};

export const loadShiftArchive = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const storage = firebase.storage();

        let storageRef = storage.ref();

        let configRef = storageRef.child('archive/config.json');

        configRef.getDownloadURL().then(function(url) {

            return fetch(url)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    return data
                })

        }).then(config => {

            let shiftsRef = storageRef.child(config.shiftsPath);

            shiftsRef.getDownloadURL().then((url) => {

                return fetch(url)
                    .then((response) => {
                        return response.json();
                    })
                    .then( (data) => {
                        let dataString = JSON.stringify(data);

                        dataString = dataString.replace(/_seconds/g, 'seconds');
                        dataString = dataString.replace(/_nanoseconds/g, 'nanoseconds');

                        return JSON.parse(dataString)
                    })
            }).then((data) => {
                dispatch({type: 'LOAD_ARCHIVE_SHIFT_SUCCESS', shifts: data });
            }).catch(error => {
                dispatch({type: 'LOAD_ARCHIVE_SHIFT_ERROR'}, error);
            })

        })

    }
};
