export const updateBatteryLevel = (carID, batteryLevel) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        let carRef = fs.collection("init").doc("cars");


        let myCar = {
            modifiedBy: getState().firebase.auth.uid,
            modified: firebase.firestore.Timestamp.now(),
            batteryLevel: batteryLevel,
        };

        carRef.update({
            [carID + '.modifiedBy']: myCar.modifiedBy,
            [carID + '.modified']: myCar.modified,
            [carID + '.batteryLevel']: myCar.batteryLevel,
        }).then(() => {
            dispatch({type: 'UPDATE_CAR_SUCCESS'});
        }).catch(error => {
            dispatch({type: 'UPDATE_CAR_ERROR'}, error);
        })
    }

};


