import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

// Initialize Firebase
//LIVE
let config = {
    apiKey: "AIzaSyBzOscYKU-u9SLjB01DwgYxi7WBaVptJMs",
    authDomain: "kiwilabs-vip-shuttle.firebaseapp.com",
    databaseURL: "https://kiwilabs-vip-shuttle.firebaseio.com",
    projectId: "kiwilabs-vip-shuttle",
    storageBucket: "kiwilabs-vip-shuttle.appspot.com",
    messagingSenderId: "821490988602"
};

//PRE
/*let config = {
    apiKey: "AIzaSyAcg8NA5RYu-mWIz_HiGDU3VkouqE9YruQ",
    authDomain: "pre-kiwilabs-vip-shuttle.firebaseapp.com",
    databaseURL: "https://pre-kiwilabs-vip-shuttle.firebaseio.com",
    projectId: "pre-kiwilabs-vip-shuttle",
    storageBucket: "pre-kiwilabs-vip-shuttle.appspot.com",
    messagingSenderId: "500013636098"
};*/


firebase.initializeApp(config);

var storage = firebase.storage();

export default firebase;