import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import {Typography} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

// Local Files


const BackgroundImage = '//www.kiwilabs.de/404_background/';


const styles = theme => ({
    root:{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: "100%",
        width: '100%',
        position: 'fixed',
        top: 0,
        overflowY: "scroll",

    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 50,
        paddingTop: 50,
        height: 'calc(100% - 100px)',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 150,
            paddingBottom: 150,
            height: 'auto'
        },
    },
    paper: {
        marginTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: "100%",
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        background: 'rgba(0,0,0,0)',
        boxShadow: "0px 0px 0px 0px",
    },
    spacingPaper: {
        width: "100%",
        borderRadius: 0,
        boxShadow: "0px 0px",
        marginTop: 50,
    },
    textPaper: {
        color: theme.palette.primary.contrastText,
        background: 'rgba(255,255,255,0.2)',
        boxShadow: "0px 0px 0px 0px",
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        marginTop: '50%'
    }
});

class NotFound extends Component {

    state = {

    }


    render(){

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    variant="h1"
                                    gutterBottom
                                    color='secondary'
                                >
                                    404
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.textPaper}>
                                    <Typography color='inherit' variant='h4'>
                                        Irgendwie bist du verloren gegangen...
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Hidden mdUp>
                            <Paper className={classes.spacingPaper} />
                        </Hidden>
                    </Paper>
                </main>
            </div>
        );

    }

}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(NotFound));