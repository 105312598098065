import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


// Local Files



const BackgroundImage = '//www.kiwilabs.de/background_shuttle_app/';


const styles = theme => ({
    progress: {
        marginTop: 'calc(50% - 25px)',
        marginBottom: 50,

    },
    root:{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: "100%",
        width: '100%',
        position: 'fixed',
        top: 0,
        overflowY: "scroll",

    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 50,
        paddingTop: 50,
        height: 'calc(100% - 100px)',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 150,
            paddingBottom: 150,
            height: 'auto'
        },
    },
    paper: {
        margin: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems:"center",
        height: "100%",
    },


});

class Loading extends Component {


    render(){

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <CircularProgress className={classes.progress} color="secondary"/>
                        <Typography gutterBottom variant="inherit">
                            Lade Daten...
                        </Typography>
                    </Paper>
                </main>
            </div>
        );

    }

}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(Loading);