import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import {Redirect} from 'react-router-dom';



// Local Files
import KeyboardDatePicker from '../layout/KeyboardDatePicker';



moment.locale('de');



const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        overflowX: 'auto',
    },
    table: {
        size: 'small'
    },
    datePickerGrid: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },


});



function compareUsers(a, b) {

    return a.employeeID >= b.employeeID
}



class DriverWorkdaysReportCard extends Component {

    state = {
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year'),
        userRedirect: false,
        redirectToUserID: '',
    }



    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleClick = (e) => {

        this.setState({
            userRedirect: true,
            redirectToUserID: e.target.id,
        })

    }


    render(){

        if (this.state.userRedirect && this.state.redirectToUserID!== '') {
            return <Redirect to={'/viewuser/' + this.state.redirectToUserID} />
        }

        const { classes,
            publicData, initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(publicData === undefined ||
            initData === undefined){
            return (
                <Paper>
                    Loading...
                </Paper>
            )
        }

        const users = publicData.users;

        let shifts = this.props._shift.archive ? this.props._shift.archive: {};
        Object.assign(shifts, publicData.shifts, initData.shifts);

        let myUsers = [];

        for(let userID in users) {

            //try to load the users
            try {

                let user = users[userID];

                myUsers.push({
                    id: userID,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    email: user.email,
                    appliedShifts: user.appliedShifts ? user.appliedShifts : [],
                    selectedShifts: user.selectedShifts ? user.selectedShifts : [],
                    role: user.role ? user.role : 'desk',
                    employeeID: user.employeeID? user.employeeID : 0,
                })
            }
            catch (e) {
                console.log('user could not be loaded correctly', userID)
            }
        }

        const myDrivers = myUsers.filter((user) => {
            return user.role === 'driver'
        }).sort(compareUsers);

        // Calc the displayed data
        let data = {};
        const filterStartDate = moment(this.state.startDate);
        const filterEndDate = moment(this.state.endDate);

        myDrivers.map( (user) => {

            user.selectedShifts.map( (shiftID) => {
                let shift = shifts[shiftID];

                if (shift !== undefined){

                    const startDate = moment(shift.startDate.seconds * 1000);
                    const endDate = moment(shift.endDate.seconds * 1000);
                    const year = startDate.format("YYYY");
                    const day = startDate.format('L');


                    if(startDate.isSameOrAfter(filterStartDate, 'day') && endDate.isSameOrBefore(filterEndDate, 'day')){

                        if (data[year] === undefined) {
                            data[year] = {}
                        }

                        if (data[year][user.id] === undefined){
                            data[year] = {
                                ...data[year],
                                [user.id]: {
                                }
                            }
                        }

                        //Update the count and total hours of a month per user
                        data[year][user.id][day] += true;

                    }
                }
            })

        });

        const dataKeys =  Object.keys(data).sort(function(a, b) {
            return moment(a, 'YYYY').isBefore(moment(b, 'YYYY'))
        })

        let activeDriver = {}

        dataKeys.forEach((key) => {
            Object.keys(data[key]).forEach((k => {
                if(['count', 'total'].includes(k) === false){
                    activeDriver[k] = true
                }
            }))
        })

        activeDriver = Object.keys(activeDriver).sort((a,b) => users[a].employeeID -  users[b].employeeID )

        return (

            <Paper className={classes.root}>
                <Grid container
                      spacing={16}
                      justify="center"
                      alignItems="center">
                    <Grid item xs={5} className={classes.datePickerGrid}>
                        <KeyboardDatePicker
                            id="startDate"
                            name="startDate"
                            label="Start-Datum"
                            value={this.state.startDate}
                            onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={5} className={classes.datePickerGrid}>
                        <KeyboardDatePicker
                            id="endDate"
                            name="endDate"
                            label="End-Datum"
                            value={this.state.endDate}
                            onChange={this.handleChange}/>
                    </Grid>
                </Grid>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fahrer</TableCell>
                                {dataKeys.map((key) => (

                                    <TableCell key={key}>{key}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeDriver.map((userID, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Link
                                            id={userID}
                                            onClick={this.handleClick}
                                        >
                                            {`${users[userID].firstName} ${users[userID].lastName} (${users[userID].employeeID})`}
                                        </Link>
                                    </TableCell>
                                    {dataKeys.map((key) => (

                                        <TableCell align="left" key={userID + key}>
                                            {(data[key][userID] !== undefined) &&
                                            `${Object.keys(data[key][userID]).length} Tage`}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

            </Paper>

        );

    }

}

DriverWorkdaysReportCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        publicData: state.firestore.data.public,
        initData: state.firestore.data.init,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(DriverWorkdaysReportCard) );