import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/es/Paper/Paper";
import CarCard from "./CarCard";





// Local Files



moment.locale('de');



const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

function compareBatteryLevel(a, b) {

    return Math.sign(a.batteryLevel - b.batteryLevel)
}

class CarPanel extends Component {


    render(){

        const { classes,
            initData} = this.props;

        // Wait until shiftPlans and shifts are loaded
        if(initData === undefined){
            return (
                <Paper>
                    Loading...
                </Paper>
            )
        }

        const cars = initData.cars;

        let myCars = [];

        for(let carID in cars) {

            //try to load the users
            try {

                let car = cars[carID];

                myCars.push({
                    id: carID,
                    batteryLevel: car.batteryLevel,
                    modified: moment(car.modified.seconds * 1000),
                    modifiedBy: car.modifiedBy,
                })
            }
            catch (e) {
                console.log('car could not be loaded correctly', carID)
            }
        }

        myCars = myCars.sort(compareBatteryLevel);

        return (

            <Paper className={classes.root}>

                {myCars.map( (car, index) => (
                    <CarCard car={car} index={index} key={index}/>
        ))}

            </Paper>

        );

    }

}

CarPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        initData: state.firestore.data.init,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(CarPanel) );