export const createShiftPlan = (shiftPlan, copyShiftPlanID) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        let create = firebase.app().functions('europe-west1').httpsCallable("shiftPlanCreate");

        shiftPlan.startDate = firebase.firestore.Timestamp.fromDate(shiftPlan.startDate)

        create({
            shiftPlan: shiftPlan,
            copyShiftPlanID: copyShiftPlanID,
        }).then(() => {
            dispatch({type: 'CREATE_SHIFTPLAN_SUCCESS'});
            console.log('CREATE_SHIFTPLAN_SUCCESS')
        }).catch(error => {
            dispatch({type: 'CREATE_SHIFTPLAN_ERROR'}, error);
            console.log('CREATE_SHIFTPLAN_ERROR')
        })
    }
};

export const setStateShiftPlan = (shiftPlanID) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        const shiftPlans =  getState().firestore.data.public.shiftPlans;

        let shiftPlanRef = fs.collection('public').doc('shiftPlans');


        switch (shiftPlans[shiftPlanID].state) {
            case 0:
            case 1:
                //Only increment the state
                // Atomically increment the population of the city by 50.
                shiftPlanRef.update({
                    [shiftPlanID + '.state']: firebase.firestore.FieldValue.increment(1)
                })
                    .then(() => {
                        dispatch({type: 'SETSTATE_SHIFTPLAN_SUCCESS'});
                        console.log('SETSTATE_SHIFTPLAN_SUCCESS')
                    }).catch(error => {
                    dispatch({type: 'SETSTATE_SHIFTPLAN_ERROR'}, error);
                    console.log('SETSTATE_SHIFTPLAN_ERROR')
                })
                break;

            case 2:
                const initShifts = getState().firestore.data.init.shifts;
                let initShiftRef = fs.collection('init').doc('shifts')
                let publicShiftRef = fs.collection('public').doc('shifts')

                // Get a new write batch
                let batch = fs.batch();

                shiftPlans[shiftPlanID].shifts.map( (shiftID) => {

                    const myShift = initShifts[shiftID];
                    const selectedDriver = myShift.selectedDriver;

                    // Add to public collection
                    batch.update(publicShiftRef,{
                        [shiftID]: myShift
                    });

                    // Remove from init collection
                    batch.update(initShiftRef,{
                        [shiftID]: firebase.firestore.FieldValue.delete()
                    });

                    // Add to user selectedShifts
                    if (selectedDriver !== undefined && selectedDriver !== ''){
                        let userRef = fs.collection("public").doc("users");
                        batch.update(userRef, {
                            [selectedDriver + ".selectedShifts"]: firebase.firestore.FieldValue.arrayUnion(shiftID)
                        });
                    }

                });

                batch.update(shiftPlanRef, {
                    [shiftPlanID + '.state']: firebase.firestore.FieldValue.increment(1)
                })

                batch.commit()
                    .then(() => {
                        dispatch({type: 'SETSTATE_SHIFTPLAN_SUCCESS'});
                        console.log('SETSTATE_SHIFTPLAN_SUCCESS')
                    }).catch(error => {
                    dispatch({type: 'SETSTATE_SHIFTPLAN_ERROR'}, error);
                    console.log('SETSTATE_SHIFTPLAN_ERROR')
                })

                break;
            default:
                console.log('SETSTATE_SHIFTPLAN_ABORTED')
        }
    }


};

export const loadShiftPlanArchive = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const storage = firebase.storage();

        let storageRef = storage.ref();

        let configRef = storageRef.child('archive/config.json');

        configRef.getDownloadURL().then(function(url) {

            return fetch(url)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    return data
                })

        }).then(config => {

            let shiftsRef = storageRef.child(config.shiftPlansPath);

            shiftsRef.getDownloadURL().then(function(url) {

                return fetch(url)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        let dataString = JSON.stringify(data);

                        dataString = dataString.replace(/_seconds/g, 'seconds');
                        dataString = dataString.replace(/_nanoseconds/g, 'nanoseconds');

                        return JSON.parse(dataString)
                    })
            }).then((data) => {
                dispatch({type: 'LOAD_ARCHIVE_SHIFTPLAN_SUCCESS', shifts: data });
            }).catch(error => {
                dispatch({type: 'LOAD_ARCHIVE_SHIFTPLAN_ERROR'}, error);
            })

        })

    }
};



