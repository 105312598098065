import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
// Material UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
// Local Files
import Loading from '../layout/Loading';
import {editUser} from "../../store/actions/userActions";
import KeyboardDatePicker from '../layout/KeyboardDatePicker';
import MainArea from "../layout/MainArea";
import Authorization from "../auth/Authorization";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";


const ProfilImage = '//www.kiwilabs.de/wp-content/uploads/2018/09/usa-49909_1280-2-614x1024.jpg';

const IBAN = require('iban');

const styles = theme => ({

    bigAvatar: {
        margin: 10,
        width: 200,
        height: 200,
    },
    gridContainer: {
        spacing: 24,
        alignItems: "center",
        justify: "center"

    },
});

class EditUser extends Component {

    state = {
        employeeID : 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        iban: '',
        bic: '',
        bank: '',
        birthday: null,
        socialSecNr: '',
        isDataLoaded: false,
    }

    handleSubmit = (e) => {
        const id = this.props.match.params.id;

        e.preventDefault()

        console.log("Submit: ", this.state, id)
        this.props.editUser(this.state, id)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }


    render() {
        const {classes, publicData, protectedData} = this.props;

        if(publicData === undefined || protectedData === undefined)
            return (<Loading />)

        const userID = this.props.match.params.id;
        const users = publicData.users;
        const userDetails = protectedData.userDetails;

        // Just Load the Data once
        if(!this.state.isDataLoaded)
        {
            let user = {};

            if ( users[userID] ) {
                user = {
                    ...users[userID]
                }
            }

            if ( userDetails[userID] ) {
                user = {
                    ...user,
                    ...userDetails[userID]
                }
            }

            this.state = {
                ...this.state,
                ...user,
                isDataLoaded: true
            }

            this.state.birthday = this.state.birthday? new Date(this.state.birthday.seconds * 1000): new Date()

            // if UserID is not valid redirect to a 404 page
            if (Object.keys(user).length === 0) {
                return <Redirect to='/user-not-found'/>
            }
        }


        const ibanIsValid = IBAN.isValid(this.state.iban);

        return (

            <MainArea>
                <Authorization redirect={true}>
                    <Grid container
                          spacing={16}
                          justify="center"
                          alignItems="center"
                    >

                        <Avatar alt="Remy Sharp" src={ProfilImage} className={classes.bigAvatar}/>
                        
                        <Grid item xs={12} />
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="firstName"
                                name="firstName"
                                label="Vorname"
                                fullWidth
                                defaultValue={this.state.firstName}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="lastName"
                                name="lastName"
                                label="Nachname"
                                fullWidth
                                defaultValue={this.state.lastName}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="inherit">
                                {this.state.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="employeeID"
                                name="employeeID"
                                label="Mitarbeiter ID"
                                type="number"
                                fullWidth
                                defaultValue={this.state.employeeID}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="phone"
                                name="phone"
                                label="Mobil"
                                fullWidth
                                defaultValue={this.state.phone}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                width="100%"
                                id="birthday"
                                name="birthday"
                                label="Geburtstag"
                                value={this.state.birthday}
                                onChange={this.handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="socialSecNr"
                                name="socialSecNr"
                                label="Sozialversicherungsnummer"
                                fullWidth
                                defaultValue={this.state.socialSecNr}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Anschrift
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="address"
                                name="address"
                                label="Adresse"
                                fullWidth
                                defaultValue={this.state.address}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="zip"
                                name="zip"
                                label="Plz"
                                fullWidth
                                defaultValue={this.state.zip}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="city"
                                name="city"
                                label="City"
                                fullWidth
                                autoComplete="city"
                                defaultValue={this.state.city}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Kontodaten
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="iban"
                                name="iban"
                                label="IBAN"
                                fullWidth
                                defaultValue={this.state.iban}
                                onChange={this.handleChange}
                            />
                            {ibanIsValid ? (
                                <FormHelperText id="iban-helper-text">IBAN ist gültig.</FormHelperText>
                            ) : (
                                <FormHelperText id="iban-helper-text" error={true}>IBAN ist ungültig!</FormHelperText>
                            )}


                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="bic"
                                name="bic"
                                label="BIC"
                                fullWidth
                                defaultValue={this.state.bic}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="bank"
                                name="bank"
                                label="Bank"
                                fullWidth
                                defaultValue={this.state.bank}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                Daten übermitteln
                            </Button>
                        </Grid>

                    </Grid>
                </Authorization>
            </MainArea>
        );

    }

}

EditUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
        protectedData: state.firestore.data.protected,
        publicData: state.firestore.data.public
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editUser: (user, id) => dispatch(editUser(user, id))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    firestoreConnect([
        {collection: 'protected'},
    ])
)(EditUser);

