import React, {Component} from 'react';
import moment from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    picker: {
        width: "100%",
    },
    datePicker: {
        width: "100%",
    },

});



moment.locale("de");

class KeyboardDatePicker extends Component {

   state = {
       value: new Date(),
   }


    handleChange = (e) => {

       if (typeof this.props.onChange === "function") {

           var event = {
               target: {
                   id: this.props.id,
                   value: e.hour(12).toDate(),
               }
           }

           this.props.onChange(event);

       }
        //e is a moment object
        this.setState({value: e.hour(12).toDate()});
    }


    render(){

        const { classes, label} = this.props;

        this.state.value = this.props.value;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale='de' moment={moment}>
                <div className={classes.picker}>
                    <DatePicker
                        className={classes.datePicker}
                        width={400}
                        keyboard
                        label={label}
                        format="DD.MM.YYYY"
                        placeholder="10.10.2018"
                        mask={value =>
                            // handle clearing outside if value can be changed outside of the component
                            value ? [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/] : []
                        }
                        value={moment(this.state.value)}
                        onChange={this.handleChange}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                    />
                </div>
            </MuiPickersUtilsProvider>
        )


    }

}

export default withStyles(styles) (KeyboardDatePicker);