// react
import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { compose } from 'redux';

// redux
import { connect } from 'react-redux';


/*
    This component checks your roles
    If your are
        - admin
        - watching own profile
        - allowed role = your role
    then its fine and the child component get called,
    otherwise there are two possibilities:
        - redirect to "/"
        - show empty content

    HOW TO USE IN JSX:
    <Authorization roles={LIST}, ownProfile={BOOLEAN} redirect={BOOLEAN}>
        <...>
    </Authorization>

    roles -> optional (Only admin allowed in case)
    redirect -> optional (False if not set)
    ownProfile -> optional (False if not set)
 */
class Authorization extends Component {

    render(){

        const { classes, children, auth, publicData, roles, ownProfile, redirect} = this.props;

        // if the data are not here just render a empty div
        if(auth === undefined || publicData === undefined) {
            return <React.Fragment/>
        }

        // Default user role is role = 'desk'
        let user_role = 'desk';
        try{
            user_role = publicData.users[auth.uid].role
        }catch(e){
            console.log('no user role found')
        }

        const id = this.props.match.params.id;

        // user is admin and roles are empty
        if(user_role === 'admin' && roles === undefined) {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            );
        }

        // its his own profile
        if(id && ownProfile) {
            if (id === auth.uid) {
                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                );
            }
        }

        // user got the allowed role
        if(roles && roles.length > 0) {
            if(roles.indexOf(user_role) > -1) {
                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                );
            }
        }

        // user is not allowed to see the content
        if (redirect) {
            return <Redirect to='/'/>
        } else {
            return <React.Fragment/>
        }
    }

}

const mapStateToProps = (state) => {
    return {
        ...state,
        auth: state.firebase.auth,
        publicData: state.firestore.data.public,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    )(Authorization);