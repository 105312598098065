import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/de";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';


// Local Files
import KeyboardTimePicker from "../layout/KeyboardTimePicker";
import {createShift} from "../../store/actions/shiftActions";


moment.locale('de');



const styles = theme => ({
    card: {
        width: "100%",
        borderRadius: 0,
        boxShadow: "0px 0px",

    },
    icon:{
        padding: theme.spacing.unit,
    },
    snack:{
        marginTop: 55,

    },
    snackContent:{
        backgroundColor: theme.palette.secondary.main,
    }

});



class AddShiftCard extends Component {

    state = {
        showSnack: false,
        error: false,
        errorMessage: '',
        startDate: moment(this.props.date).hour(7).minute(30),
        endDate: moment(this.props.date).hour(13).minute(0),
    }

    handleAdd = (e) => {
        const {startDate, endDate} = this.state

        if(startDate.valueOf() >= endDate.valueOf())
        {
            this.setState({
                error: true,
                errorMessage: 'Der Startzeitpunkt muss vor dem Ende liegen!'
            })
        }
        else {
            this.setState({
                error: false,
                errorMessage: '',
                showSnack: true,
            })

            this.props.createShift(this.props.shiftPlanID,
                {
                startDate: moment(this.state.startDate).toDate(),
                endDate: moment(this.state.endDate).toDate(),
                },
                this.props.shiftPlanState
                )

        }

    }

    handleTimeChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        console.log(this.state, e)
    }

    handleClose = (event) => {

        this.setState({ showSnack: false });
    };

    render(){

        const { classes } = this.props;


        return (

            <Card className={classes.card}>
                <Snackbar
                    className={classes.snack}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.showSnack}
                    autoHideDuration={1000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        className={classes.snackContent}
                        aria-describedby="add shift snackbar"
                        elevation={0}
                        message={<Typography color='inherit' align='center'>Schicht hinzugefügt.</Typography>}
                    />
                </Snackbar>
                <CardContent>
                    <Grid
                        container
                        spacing={16}
                        alignItems="center"
                        direction="row"
                        justify="space-between"
                    >
                        <Grid item xs={6}>

                            <KeyboardTimePicker
                                id="startDate"
                                label="Start"
                                value={this.state.startDate}
                                onChange={this.handleTimeChange}/>

                        </Grid>
                        <Grid item xs={6}>

                            <KeyboardTimePicker
                                id="endDate"
                                label="Ende"
                                value={this.state.endDate}
                                onChange={this.handleTimeChange}/>

                        </Grid>
                        {this.state.error &&
                            <Grid item xs={12}>
                                <Typography align="center" color='error'> {this.state.errorMessage} </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.handleAdd}
                            >
                                Schicht hinzufügen
                            </Button>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

        );

    }

}

AddShiftCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        createShift: (shiftPlanID, newShift, shiftPlanState) => dispatch(createShift(shiftPlanID, newShift, shiftPlanState)),
    }
}

export default withStyles(styles)( connect(mapStateToProps, mapDispatchToProps)(AddShiftCard) );